// import { getFirestore } from "firebase/firestore";
// const { collection, getDocs } = require("firebase/firestore");

import { collection, getDocs } from "firebase/firestore";
import { useContext, forwardRef, useState } from "react"
import { GlobalContext } from "../../../../GlobalContext"
import { ref as storageRef, uploadBytes } from "firebase/storage"

export const All = () => {

    // const querySnapshot = await getDocs(collection(db, "cities"));
    // querySnapshot.forEach((doc) => {
    //   // doc.data() is never undefined for query doc snapshots
    //   console.log(doc.id, " => ", doc.data());
    // })


    return (
        <div>
            This is All Blogs
        </div>
    )
}