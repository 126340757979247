import { generateRandomID } from "./GenerateRandomId"
import { getImageType } from "./GetImageType"

export const formatBlogContent = (content, blogId) => {

    const removeDeletedContent = content.filter(x => !x.deleted)

    const addedItemOrder = removeDeletedContent.map((x, i) => {
        let tempObj = {
            ...x,
            order: i
        }

        if (x.type === 'picture') {
            const tempImageId = generateRandomID()
            tempObj.imageId = tempImageId
            tempObj.pathToImage = `/${blogId}/${tempImageId}.${getImageType(x.value.type)}`
        }
        return tempObj
    })
    return addedItemOrder
}