import { useEffect, useContext } from "react"
import { GlobalContext } from "../../GlobalContext"
import CircularProgress from '@mui/material/CircularProgress'
import { PodcastList } from './PodcastList'
import { collection, getDocs, orderBy, query } from "firebase/firestore" 
import { filterUpcomingPodcasts } from "./helpers/FilterUpcomingPodcasts"

export const Podcasts = () => {
    const { podcasts: { data, savePodcasts }, database: { db } } = useContext(GlobalContext)

    useEffect(() => {
        (async () => {
            if (!data && Boolean(db)) {
                const podcastData = []
                const podcastsDbRef = collection(db, 'podcasts')
                const podcastQuery = query(podcastsDbRef, orderBy("dateOfPodcast", "desc"));
                const querySnapshot = await getDocs(podcastQuery)
                querySnapshot.forEach((doc) => podcastData.push({ ...doc.data(), id: doc.id }))
                savePodcasts(filterUpcomingPodcasts(podcastData))
            }
        })()
    }, [db]) // eslint-disable-line

    const PodcastContent = () => {
        if (Boolean(data)) return <PodcastList />


        return (
            <div className='d-podcasts-loading-container'>
                <div className='d-podcasts-loading-feedback'>Getting Episodes</div>
                <CircularProgress color='inherit' size={20} />
            </div>

        ) 
    }

    return (
        <div className="desktop-section-container">
            <div className="d-podcasts-header fancy-font">The Funeral Dirge</div>
            <div className="d-podcast-list-fade">
                <div className="d-postcast-list-container-top-fade"></div>
                <PodcastContent />
                <div className="d-postcast-list-container-bottom-fade"></div>
            </div>
        </div>
    )
}