import { useEffect, useContext } from "react"
import { GlobalContext } from "../../GlobalContext"
import CircularProgress from '@mui/material/CircularProgress'
import { BlogList } from "./BlogList"
import { collection, getDocs, orderBy, query } from "firebase/firestore" 

export const Blogs = () => {

    const { blogs: { data, saveBlogs }, database: { db } } = useContext(GlobalContext)

    useEffect(() => {
        (async () => {
            if (!data && Boolean(db)) {
                const blogData = []
                const blogDbRef = collection(db, 'blog')
                const blogsQuery = query(blogDbRef, orderBy("timestamp", "desc"));
                const querySnapshot = await getDocs(blogsQuery)
                querySnapshot.forEach((doc) => blogData.push({ ...doc.data(), id: doc.id }))
                saveBlogs((blogData))
            }
        })()
    }, [db]) // eslint-disable-line

    const BlogContent = () => {
        if (Boolean(data)) return <BlogList />

        return (
            <div className='d-podcasts-loading-container'>
                <div className='d-podcasts-loading-feedback'>Loading Blog</div>
                <CircularProgress color='inherit' size={20} />
            </div>
        ) 
    }

    return (
        <div className="desktop-section-container">
            <div className="d-blog-header fancy-font">Blog</div>
            <div className="d-podcast-list-fade">
                <div className="d-postcast-list-container-top-fade"></div>
                <BlogContent />
                <div className="d-postcast-list-container-bottom-fade"></div>
            </div>
        </div>
    )
}