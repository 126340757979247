import { forwardRef, useState } from "react"
import { Backdrop } from "@mui/material"
import { PreviewBackdrop } from './PreviewBackdrop'

export const SubmitButton = forwardRef((props, ref) => {

    const { setRoute } = props

    let includedItemCount = 0
    ref.current.content.forEach(item => item.deleted ? null : includedItemCount += 1)

    const [error, setError] = useState(null)
    const [previewing, setPreviewing] = useState(false)

    const clearError = () => setError(null)

    const checkForErrors = () => {
        if (!includedItemCount) return
        setError(null)
        let tempError = null

        if (!ref.current.title) tempError = 'Title cannot be blank'
        if (!ref.current.thumbnail) tempError = 'Thumbnail required for blog post'
        if (!ref.current.author) tempError = 'Please select who is authoring this blog post'

        ref.current.content.forEach(item => {
            if (tempError || item.deleted) return
            if (['header', 'body', 'list-item', 'emphasis'].includes(item.type)) {
                if (!item.text) tempError = `${item.type} cannot be blank`
            }
            if (['picture'].includes(item.type)) {
                if (!item.value) tempError = `Image is required`
            }
            if (['youtube', 'external'].includes(item.type)) {
                if (!item.url) tempError = `URL is required for ${item.type}`
                
                let urlRegex = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?'+ // port
                '(\\/[-a-z\\d%_.~+]*)*'+ // path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

                if (!urlRegex.test(item.url)) tempError = `URL for ${item.type} is not valid`
            }
            if (item.type === 'youtube') {
                let youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/gi
                if (!youtubeUrlRegex.test(item.url)) {
                    tempError = `URL for YouTube is not valid`
                }
            }
        })
        if (tempError) {
            setError(tempError)
            return
        }
        setPreviewing(true)
    }

    let buttonStyle
    if (includedItemCount) {
        buttonStyle = {
            cursor: 'pointer',
            opacity: 1,
            backgroundColor: 'white'
        }
    } else {
        buttonStyle = {
            cursor: 'not-allowed',
            opacity: 0.3,
            backgroundColor: 'lightgray'
        }
    }

    const ErrorBackdrop = () => {
        const errorBackdropStyle = {
            border: '2px solid red',
            borderRadius: '4px',
            backgroundColor: 'white',
            margin: 'auto',
            zIndex: 100,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
        return (
            <Backdrop
                style={errorBackdropStyle}
                open={Boolean(error)}>
                    {error}
                    <div onClick={clearError} className="d-blog-close-error-button">Close</div>
            </Backdrop>
        )
    }


    return (
        <div>
            <ErrorBackdrop />
            <PreviewBackdrop setRoute={setRoute} previewing={previewing} setPreviewing={setPreviewing} ref={ref} />
            <div className={`d-blog-submit-button-container ${includedItemCount ? 'd-blog-submit-button-hover' : ''}`} style={buttonStyle} onClick={checkForErrors}>
                Preview
            </div>
        </div>
    )
})