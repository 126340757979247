import { Backdrop } from "@mui/material"
import { useContext, forwardRef, useState } from "react"
import { GlobalContext } from "../../../../../GlobalContext"
import CloseIcon from '@mui/icons-material/Close'
import { GenerateBlogHeader } from "../../../../Blog/GenerateBlogHeader"
import { GenerateBlogContent } from "../../../../Blog/GenerateBlogContent"
import { formatBlogContent } from "../helpers/FormatBlogContent"
import { ref as storageRef, uploadBytes } from "firebase/storage"
import { generateRandomID } from "../helpers/GenerateRandomId"
import { getImageType } from "../helpers/GetImageType"
import { collection, addDoc } from "firebase/firestore" 
import { formatContentType } from '../helpers/FormatContentType'

export const PreviewBackdrop = forwardRef((props, ref) => {

    const { previewing, setPreviewing, setRoute } = props
    const { theme: { primary }, storage: { bucket }, database: { db } } = useContext(GlobalContext)
    const [submitStatus, setSubmitStatus] = useState(['preview', '']) //! submitting // success // failure
    const previewBackdropStyle = { position: 'fixed', zIndex: 200, backgroundColor: primary }

    if (!previewing) return <></>

    const CreateBlogPostButton = () => {

        const handleSubmitNewBlog = async () => {
            const blogId = generateRandomID()
            const formattedContent = formatBlogContent(ref.current.content, blogId)

            const pathToThumbnail = `blog/${blogId}/thumbnail.${getImageType(ref.current.thumbnail.type)}`

            const blogThumbnailRef = storageRef(bucket, `blog/${blogId}/thumbnail.${getImageType(ref.current.thumbnail.type)}`)

            let checkForAdditionalImages = false
            formattedContent.forEach(item => {
                if (checkForAdditionalImages) return
                if (item.type === 'picture') return checkForAdditionalImages = true
                else return
            })

            setSubmitStatus(['submitting', 'Uploading Thumbnail'])

            await uploadBytes(blogThumbnailRef, ref.current.thumbnail)
            .then(() => {
                setSubmitStatus(['submitting', 'Successfully Uploaded Thumbnail'])
            })
            .catch(err => {
                setSubmitStatus(['submitting', `Error: ${err.message}`])
            })

            if (checkForAdditionalImages) {
                try {
                    const imagesToUpload = formattedContent.filter(x => x.type === 'picture')
    
                    const uploadPromises = imagesToUpload.map((image) => {
                      const imageRef = storageRef(bucket, `blog/${blogId}/${image.imageId}.${getImageType(image.value.type)}`);
                      return uploadBytes(imageRef, image.value);
                    })
                
                    await Promise.all(uploadPromises);
                    setTimeout(() => setSubmitStatus(['submitting', 'All Additional Images Uploaded Successfully']), 1200)
                } catch (error) {
                    setSubmitStatus(['submitting', 'Unable to upload images']);
                }
            }

            const now = new Date()
            const timestamp = now.toString()
            let newDocId

            await addDoc(collection(db, "blog"), { 
                title: ref.current.title,
                subtitle: ref.current.subtitle,
                author: ref.current.author,
                blogId: blogId,
                timestamp: timestamp,
                pathToThumbnail
            })
            .then(doc => {
                newDocId = doc.id
            })
            .catch(err => console.error(err.code))
            try {
                const uploadPromises = formattedContent.map((cont) => {
                    return addDoc(collection(db, 'blog', newDocId, 'content'), {
                        ...formatContentType(cont)
                    })
                })
                await Promise.all(uploadPromises);
                setTimeout(() => setSubmitStatus(['submitting', 'All Content Uploaded Successfully']), 800)
            } catch (error) {
                setSubmitStatus(['submitting', 'Unable to upload content']);
            }

            setTimeout(() => setSubmitStatus(['submitting', 'Blog Post Created']), 2000)
         
            setTimeout(() => {
                setRoute('Home')
            }, 5000)
        }

        return (
            <div onClick={handleSubmitNewBlog} className='d-blog-create-blog-final-button'>
                Publish
            </div>
        )
    }

    const { title, subtitle, thumbnail, author, content } = ref.current
    const currentTime = new Date()

    const PreviewContent = () => {
        if (submitStatus[0] === 'preview') {
            return (
                <div className="d-blog-preview-container">
                    <div className="d-blog-preview-header">
                        <div className="d-blog-preview-header-text">Blog Post Preview</div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <CreateBlogPostButton />
                            <div className="d-blog-preview-header-close" onClick={() => setPreviewing(false)}>
                                <CloseIcon style={{ color: 'white', fontSize: '40px'}} />
                            </div>
                        </div>
                    </div>
                    <GenerateBlogHeader title={title} subtitle={subtitle} thumbnail={thumbnail} author={author} timestamp={currentTime} />
                    <GenerateBlogContent content={content} />
                </div>
            )
        } else if (submitStatus[0] === 'submitting') {

            return (
                <div className="d-blog-preview-container">
                    <div className="d-blog-preview-submit-header">
                        Submitting Blog
                    </div>
                    <div className="d-blog-preview-submit-status">
                        {submitStatus[1]}
                    </div>
                </div>
            )
        }

    }


    return (
        <Backdrop
            style={previewBackdropStyle}
            open={previewing}
            >   
            <PreviewContent />
        </Backdrop>
    )
})