import { useState, useContext } from "react"
import { Backdrop } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { GlobalContext } from '../../GlobalContext'

import { AdminConsole } from './AdminConsole'
import { SignIn } from "./SignIn"
import { SignUp } from "./SignUp"

export const AuthContainer = () => {
    const { user: { data, showingAuthContainer, hideAuth }} = useContext(GlobalContext)

    const [registering, setRegistering] = useState(false)

    let backdropContent
    if (data) backdropContent = <AdminConsole />
    else if (registering) backdropContent = <SignUp setRegistering={setRegistering} />
    else if (!registering) backdropContent = <SignIn setRegistering={setRegistering} />
    else backdropContent = <div>Error</div>

    return (
        <Backdrop open={showingAuthContainer} style={{ zIndex: 200, backgroundColor: 'rgba(88, 83, 58, 0.8)' }}>
            <div className="d-auth-container">
                {backdropContent}
                <div onClick={hideAuth} className="d-auth-close-icon"><CloseIcon fontSize="large" /></div>
            </div>
        </Backdrop>
    )
} 