import { cleanUpTimestamp } from "./helpers/CleanUpTimestamp"

const Title = ({ title}) => <div className="d-blog-title">{title}</div>
const Subtitle = ({ subtitle }) => !subtitle ? <></> : <div className="d-blog-subtitle">{subtitle}</div>
const Thumbnail = ({ thumbnail, guest }) => <img src={guest ? thumbnail : URL.createObjectURL(thumbnail)} alt='preview for blog' className="d-blog-thumbnail" />
const Author = ({ author }) => <div className="d-blog-author">By: {author}</div>
const Timestamp = ({ timestamp, guest }) => <div className='d-blog-timestamp'>{cleanUpTimestamp(timestamp, guest)}</div>

export const GenerateBlogHeader = ({ title, subtitle, thumbnail, author, timestamp, guest }) => (
    <div className='d-blog-details-container'>
        <Title title={title} />
        <Subtitle subtitle={subtitle} />
        <Author author={author} />
        <Timestamp timestamp={timestamp} guest={guest} />
        <Thumbnail thumbnail={thumbnail} guest={guest} />
    </div>
)