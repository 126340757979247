import { createContext, Component } from 'react'

import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getAuth,onAuthStateChanged } from "firebase/auth"
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyB5XwzK5kUI6NLHSa7BvpOIFdExAPiukOk",
  authDomain: "watchman-client.firebaseapp.com",
  projectId: "watchman-client",
  storageBucket: "watchman-client.appspot.com",
  messagingSenderId: "580421720157",
  appId: "1:580421720157:web:5cb0bde212819afd6015b4",
  measurementId: "G-BWM3W5HS20"
};


export const GlobalContext = createContext()

export class GlobalContextProvider extends Component {
    constructor() {
        super()
        this.state = {
            fadeOn: true,
            removeFade: () => this.setState(s => s.fadeOn = false),
            user: {
                data: null,
                setData: data => this.setState(s => s.user.data = data),
                showingAuthContainer: false,
                showAuth: () => this.setState(s => s.user.showingAuthContainer = true),
                hideAuth: () => this.setState(s => s.user.showingAuthContainer = false),
            },
            database: {
                db: null,
                setDatabase: db => this.setState(s => s.database.db = db)
            },
            podcasts: {
                data: null,
                savePodcasts: data => this.setState(s => s.podcasts.data = data)
            },
            blogs: {
                data: null,
                saveBlogs: data => this.setState(s => s.blogs.data = data)
            },
            storage: {
                bucket: null,
                setBucket: bucket => this.setState(s => s.storage.bucket = bucket)
            },
            theme: { 
                primary: 'rgb(88, 83, 58)',
                light: 'white'
            },
            notifications: {
                text: null,
                setNotification: text =>  this.setState(s => s.notifications.text = text),
                clearNotification: () => this.setState(s => s.notifications.text = null)
            } 
        }
    }

    componentDidMount() {
        const app = initializeApp(firebaseConfig);
        // eslint-disable-next-line
        const analytics = getAnalytics(app);
        const auth = getAuth();
        const db = getFirestore(app)
        this.state.database.setDatabase(db)
        const storage = getStorage(app)
        this.state.storage.setBucket(storage)

        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.state.user.setData(user)

                //! go to database and get document associated with user by seature for field userid or uid
                //! get tat persons name and info, set notification with name, save info to context

                this.state.notifications.setNotification('Welcome back')
                setTimeout(() => this.state.notifications.clearNotification, 1000)
            } else {
                this.state.user.setData(null)
                console.log('not signed in')
            }
        })
        
    }

    render() {
        return (
            <GlobalContext.Provider value={this.state}>
                {this.props.children}
            </GlobalContext.Provider>
        )
    }
}