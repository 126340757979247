import { getAuth } from "firebase/auth"
import { useContext } from "react"
import { GlobalContext } from "../../../GlobalContext"



export const AuthHome = ({ setRoute }) => {

    const { user: { setData, hideAuth } } = useContext(GlobalContext)

    const handleSignOut = () => {
        const auth = getAuth()
        
        auth.signOut()
        setData(null)
        hideAuth()
    }

    const adminHomeOptions = [
        'Create New Podcast', 
        'View Podcasts', 
        'Create New Blog Post', 
        'View All Blog Posts'
    // , 'Edit Your Profile'
]


    return (
        <div className="d-admin-content-container">
            {adminHomeOptions.map((item, i) => (
                <div key={`admin-content-button-${i}`} onClick={() => setRoute(item)} className='d-admin-content-home-button'>
                    {item}
                </div>
            ))}

           <div className="d-auth-sign-out-button" onClick={handleSignOut}>Sign Out</div>
        </div>
    )
}