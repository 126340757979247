import { useContext } from "react"
import { GlobalContext } from "../../GlobalContext"
import { Link } from "react-router-dom"
import { formatBlogDate } from "./helpers/FormatBlogDate"

export const BlogList = () => {

    const { blogs: { data } } = useContext(GlobalContext)

    const BlogItem = ({ blog, order }) => {
        // eslint-disable-next-line
        const { title, id, subtitle, timestamp, blogId } = blog

        const blogStyle = Boolean(order) ? {
            flexShrink: 0,
            boxSizing: 'border-box',
            padding: '20px 10px 10px'
        } : {
            marginBottom: '50px', 
            marginTop: '25px',
            minHeight: '150px',
            flexShrink: 0,
            boxSizing: 'border-box',
            padding: '30px 20px 20px' 
        }

        const MostRecent = () => Boolean(order) ? <></> : <div className="d-podcast-most-recent-label">Most Recent</div>

        return (
            <Link to={`${blogId}`} style={{ width: '100%' }}>
                <div className="d-blog-list-item-guest" style={blogStyle}>
                    <div className="d-blog-list-item-main">
                        <div className="d-blog-list-item-main-title">
                            {title}
                        </div>
                        <div className="d-blog-list-item-main-subtitle">
                            {subtitle}
                        </div>
                        <div className="d-blog-list-item-main-description">
                            {/* {trimStringLength(description, 240)} */}
                        </div>
                    </div>
                    <div className="d-blog-list-item-details">
                        {formatBlogDate(timestamp)}
                    </div>
                    <MostRecent />
                </div>
            </Link>
        )
    }


    return (
        <div className="d-blog-list-container">
           {data.map((blog, i) => <BlogItem key={`podcast-item-${blog.id}`} blog={blog} order={i}/>)}
        </div>
    )
}