import { TextField } from "@mui/material"
import { useRef, useState, useContext } from "react"
import ReactAudioPlayer from 'react-audio-player'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import { GlobalContext } from '../../../../GlobalContext'
import { ref, uploadBytes } from "firebase/storage"
import { collection, addDoc } from "firebase/firestore" 
import moment from "moment"

export const Create = ({ setRoute }) => {

    const { storage: { bucket }, database: { db } } = useContext(GlobalContext)

    const textfieldStyle = { width: '90%', margin: '0 auto 10px'}

    const thumbnailRef = useRef(null), audioRef = useRef(null), dateRef = useRef(null), linkRef = useRef({ link : '', linkText: '', guestName: '', youtubeUrl: '' }), titleRef = useRef(''), descriptionRef = useRef('')

    const AudioInput = () => {

        const inputRef = useRef(), [audioAttached, setAudioAttached] = useState(false)

        const url = audioAttached ? URL.createObjectURL(audioRef.current) : null

        const handleAttachAudio = e => {
            audioRef.current = e.target.files[0]
            setAudioAttached(true)
        }

        const AudioIsAttached = () => {
            if (!audioAttached) return <></>

            const handleRemoveAudio = () => {
                audioRef.current = null
                inputRef.current.value = null
                setAudioAttached(false)
            }

            return (
                <div className="d-a-c-p-audio-options">
                    <ReactAudioPlayer src={url} controls style={{ width: '100%' }} />
                    <div onClick={handleRemoveAudio} className="d-a-c-p-remove-audio-button">Remove Audio</div>
                </div>
            )
        }

        return (
            <div className="d-admin-create-podcast-input-container">
                <input accept="audio/*" ref={inputRef} type="file" id="temppodcastid" name="podcast" className="d-admin-create-podcast-input" onChange={handleAttachAudio} />
                <AudioIsAttached />
            </div>
        )
    }

    const Title = () => <TextField onChange={e => titleRef.current = e.target.value} label='Title of Podcast' type='text' color='primary' variant="standard" style={textfieldStyle}/>
    
    const Description = () => <TextField onChange={e => descriptionRef.current = e.target.value} label='Description' multiline maxRows={3} type='text' color='primary' variant="standard" style={textfieldStyle}/>
    
    const Thumbnail = () => {

        const [thumbnailAttached, setThumbnailAttached] = useState(false)

        const handleAttachThumbnail = e => {
            thumbnailRef.current = e.target.files[0]
            setThumbnailAttached(URL.createObjectURL(e.target.files[0]))
        }

        const TheImage = () => thumbnailAttached ? <img src={thumbnailAttached} alt='preview for podcast' className="d-a-c-p-thumbnail-preview" /> : <></>

        return (
            <div className="d-a-c-p-thumbnail-upload">
                <label htmlFor="attachImage">
                    <div className="d-a-c-p-thumbnail-upload-icon">
                        <AddAPhotoIcon color='inherit' fontSize="inherit"/>
                        <input onChange={handleAttachThumbnail} accept="image/*" type='file' id='attachImage' style={{ position: 'absolute', display: 'none', cursor: 'pointer', fontSize: '50px' }}/>
                    </div>
                </label>
                <TheImage />
            </div>
        )
    }

    const Date = () => {
        const handleAttatchDate = e => dateRef.current = e.target.value
        return <div><input type='date' onChange={handleAttatchDate} className="d-a-c-p-date-picker" /></div>
    }

    const Links = () => {

        const handleUpdateLinkValue = (target, value) => linkRef.current[target] = value

        return (
            <div className="d-a-c-p-link-container">
                <div className="d-a-c-p-link-header">Optional: Add link to video uploaded to youtube</div>
                <TextField onChange={e => handleUpdateLinkValue('youtubeUrl', e.target.value)} label='Youtube URL' type='text' color='primary' variant="standard" style={textfieldStyle}/>
                <div className="d-a-c-p-link-header">Optional: Add links to external websites and text used for the hyperlink</div>
                <TextField onChange={e => handleUpdateLinkValue('link', e.target.value)} label='Link' type='text' color='primary' variant="standard" style={textfieldStyle} />
                <TextField onChange={e => handleUpdateLinkValue('linkText', e.target.value)} label='Link Text' type='text' color='primary' variant="standard" style={textfieldStyle}/>
                <div className="d-a-c-p-link-header">Optional: Add name of guest or guests featured on the podcast</div>
                <TextField onChange={e => handleUpdateLinkValue('guestName', e.target.value)} label='Guest Name(s)' type='text' color='primary' variant="standard" style={textfieldStyle}/>
            </div>
        )
    }

    const Divider = () => <div className="d-a-c-p-section-divider"></div>

    const SubmitButton = () => {
        const [error, setError] = useState('')
        const [progress, setProgress] = useState('')

        const Errors = () => <div className="d-a-c-p-errors-container">{error}</div>
        
        const createPodcastEpisode = () => {
            setProgress('Uploading Audio File')
            const year = dateRef.current.split('-')[0]
            const month = ['January', 'February', 'March', 'April', 'May', 'June', ' July', 'August', 'September', 'October', 'November', 'December'][Number(dateRef.current.split('-')[1]) - 1]
            const day = String(Number(dateRef.current.split('-')[2]))

            const audioExtension = audioRef.current.name.split('.')[audioRef.current.name.split('.').length - 1]
            const podcastAudioRef = ref(bucket, `podcasts/${year}-${month}/${month}-${day}/Audio.${audioExtension}`)

            uploadBytes(podcastAudioRef, audioRef.current).then((audioSnap) => {
                setProgress('Audio File Uploaded')

                setTimeout(() => setProgress('Uploading Thumbnail'), 200)
                const thumbnailExtension = thumbnailRef.current.name.split('.')[thumbnailRef.current.name.split('.').length - 1]
                const podcastThumbnailRef = ref(bucket, `podcasts/${year}-${month}/${month}-${day}/Thumbnail.${thumbnailExtension}`)

                uploadBytes(podcastThumbnailRef, thumbnailRef.current).then((thumbSnap) => {
                    setProgress('Thumbnail Uploaded')
                    setTimeout(() => setProgress('Adding Podcast to Firestore'), 400)
                    addDoc(collection(db, "podcasts"), { 
                        title: titleRef.current,
                        description: descriptionRef.current,
                        audioRoute: audioSnap.metadata.fullPath,
                        thumbRoute: thumbSnap.metadata.fullPath,
                        dateOfPodcast: dateRef.current,
                        dateAdded: moment().format('MMMM Do YYYY, h:mm:ss a'),
                        linkUrl: linkRef.current.link,
                        linkText: linkRef.current.linkText,
                        guestNames: linkRef.current.guestName,
                        youtubeUrl: linkRef.current.youtubeUrl
                    })
                    .then(docRef => {
                        setProgress('Podcast Successfully Added')
                    })
                    .then(() => {
                        setTimeout(() => {
                            setRoute('Created New Podcast')
                            setProgress('')
                        }, 3000)
                    })
                    .catch(e => {
                        setError(`Error uploading to firestore: ${e.code}`)
                        setProgress('')
                        console.error(e.code)
                    })
                })
                .catch(e => {
                    setError(`Error uploading thumbnail: ${e.code}`)
                    setProgress('')
                    console.error(e.code)
                })

            })
            .catch(e => {
                setError(`Error uploading audio: ${e.code}`)
                setProgress('')
                console.error(e.code)
            })

        }


        const handlePreSubmitCheck = () => {
            if (!audioRef.current) return setError('Please include an audio file')
            else if (!thumbnailRef.current) return setError('Please include a thumbnail')
            else if (!titleRef.current) return setError('Please include a title for the podcast')
            else if (!descriptionRef.current) return setError('Please include description for the podcast')
            else if (!dateRef.current) return setError('Please include the date of the podcast')
            else {
                setError('')
                createPodcastEpisode()
            }
        }

        const SubmissionProgress = () => progress ? <div className="d-a-c-p-submission-container">Progress: {progress}</div> : <></>

 
        return progress ? <SubmissionProgress /> : (
            <div className="d-a-c-p-submit-container">
                <SubmissionProgress />
                <Errors />
                <div className="d-a-c-p-submit-podcast-button" onClick={handlePreSubmitCheck}>
                    Submit
                </div>
            </div>
        )
    }
    //! come back to do categories?

    return (
        <div className="d-admin-sub-page-container">
            <AudioInput />
            <Thumbnail />
            <Title />
            <Description />
            <Date />
            <SubmitButton />
            <Divider />
            <Links />
        </div>
    )
}