import { useContext } from "react"
import { GlobalContext } from "../../GlobalContext"
import { Link } from "react-router-dom"
import { trimStringLength } from "../../helpers/TrimStringLength"
import { formatPodcastDate } from "../../helpers/FormatPodcastDate"

export const PodcastList = () => {

    const { podcasts: { data } } = useContext(GlobalContext)

    const PodcastItem = ({ podcast, order }) => {
        // eslint-disable-next-line
        const { audioRoute, dateAdded, dateOfPodcast, description, guestNames, id, linkText, linkUrl, thumbRoute, title, youtubeUrl } = podcast

        const podcastStyle = Boolean(order) ? {
            flexShrink: 0,
            boxSizing: 'border-box',
            padding: '20px 10px 10px'
        } : {
            marginBottom: '50px', 
            marginTop: '25px',
            minHeight: '150px',
            flexShrink: 0,
            boxSizing: 'border-box',
            padding: '30px 20px 20px' 
        }

        const MostRecent = () => Boolean(order) ? <></> : <div className="d-podcast-most-recent-label">Most Recent</div>

        return (
            <Link to={`${dateOfPodcast}`} style={{ width: '100%' }}>
                <div className="d-podcast-list-item" style={podcastStyle}>
                    <div className="d-podcast-list-item-main">
                        <div className="d-podcast-list-item-main-title">
                            {title}
                        </div>
                        <div className="d-podcast-list-item-main-description">
                            {trimStringLength(description, 240)}
                        </div>
                    </div>
                    <div className="d-podcast-list-item-details">
                        {formatPodcastDate(dateOfPodcast)}
                    </div>
                    <MostRecent />
                </div>
            </Link>
        )
    }


    return (
        <div className="d-postcast-list-container">
           {data.map((podcast, i) => <PodcastItem key={`podcast-item-${podcast.id}`} podcast={podcast} order={i}/>)}
        </div>
    )
}