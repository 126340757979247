export const cleanUpTimestamp = (timestamp, guest) => {
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friay', 'Saturday']

    if (guest) {

        const newTime = new Date(timestamp)

        let day = newTime.getDate()
        let month = newTime.getMonth()
        let year = newTime.getFullYear()
        let dayOfTheWeek = newTime.getDay()
        let hours = newTime.getHours()
        let minutes = newTime.getMinutes().toString().padStart(2, '0')
        let finalHour, extension
    
        if (hours < 11) {
            if (hours === 0) finalHour = '12'
            else finalHour = hours
            extension = 'AM'
        } else {
            if (hours === 11) finalHour = '12'
            else finalHour = hours - 12
            extension = 'PM'
        }
    
        return `${weekdays[dayOfTheWeek]} ${months[month]} ${day} ${year}, ${finalHour}:${minutes} ${extension}`

    } else {
        let day = timestamp.getDate()
        let month = timestamp.getMonth()
        let year = timestamp.getFullYear()
        let dayOfTheWeek = timestamp.getDay()
        let hours = timestamp.getHours()
        let minutes = timestamp.getMinutes().toString().padStart(2, '0')
        let finalHour, extension
    
        if (hours < 11) {
            if (hours === 0) finalHour = '12'
            else finalHour = hours
            extension = 'AM'
        } else {
            if (hours === 11) finalHour = '12'
            else finalHour = hours - 12
            extension = 'PM'
        }
    
        return `${weekdays[dayOfTheWeek]} ${months[month]} ${day} ${year}, ${finalHour}:${minutes} ${extension}`

    }

}