// import { OfficeHours } from './OfficeHours'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import FaxIcon from '@mui/icons-material/Fax'

// const contactItemStyle = { fontSize: '60px' },
// ContactItemsTopRow = [
//     {
//         id: 111,
//         name: 'phone1',
//         label: 'Main Phone Number',
//         link: '',
//         text: '',
//         icon: <PhoneIcon style={contactItemStyle} />
//     },
//     {
//         id: 222,
//         name: 'fax',
//         label: 'Fax',
//         link: null,
//         text: '',
//         icon: <FaxIcon style={contactItemStyle} />
//     }
// ], ContactItemsBottomRow = [
//     {
//         id: 333,
//         name: 'phone2',
//         label: 'Cell Phone Number',
//         link: '',
//         text: '',
//         icon: <PhoneIcon style={contactItemStyle} />
//     },
//     {
//         id: 444,
//         name: 'email',
//         label: 'Email',
//         link: '',
//         text: '',
//         icon: <EmailIcon style={contactItemStyle} />
//     }
// ]


export const Contact = () => {

    const NewContactInfo = () => {
    
        return (
            <div className='d-new-contact-info'>
                <div className="d-new-contact-header">Call to schedule <a className='d-new-contact-link' href='tel:1-617-691-7100'>1-617-691-7100</a> or email us at</div>
                <div className="d-new-contact-header"><a className='d-new-contact-link' href='mailto:brissgriefprocesscoach@debrabrissinc.com'>BrissGriefProcessCoach@DebraBrissInc.com</a></div>
            </div>
        )
    }

    return (
        <div className="desktop-section-container">
            <div className="d-contact-container">
                <NewContactInfo />
                {/* <div className="d-contact-section-container">
                    {ContactItemsTopRow.map((item, i) => (
                        <a href={item.link} key={`top-row-item-${i}`} className='d-contact-item-container'>
                            {item.icon}
                            <div className="d-contact-item-text">
                                <div className="d-contact-item-label">{item.label}</div>
                                {item.text}
                            </div>
                        </a>
                    ))}
                </div>
                <div className="d-contact-section-container">
                    {ContactItemsBottomRow.map((item, i) => (
                        <a href={item.link} key={`bottom-row-item-${i}`} className='d-contact-item-container'>
                            {item.icon}
                            <div className="d-contact-item-text">
                                <div className="d-contact-item-label">{item.label}</div>
                                {item.text}
                            </div>
                        </a>
                    ))}
                </div> */}
            </div>
            {/* <OfficeHours /> */}
            <div className="d-contact-padding"></div>
        </div>
    )
}