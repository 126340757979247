export const formatPodcastDate = date => {
    const [year, month, day] = date.split('-')
    const today = new Date()
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    let ex
    if ([1, 21, 31].includes(Number(day))) ex = 'st'
    else if ([2, 22].includes(Number(day))) ex = 'nd'
    else if ([3, 23].includes(Number(day))) ex = 'rd'
    else ex = 'th'

    return `${months[Number(month) - 1]} ${day}${ex}${Number(year) === today.getFullYear() ? '' : ` ${year}`}`
}