export const formatBlogDate = date => {

    const [weekday, month, day, year] = date.split(' ')

    let ex
    if ([1, 21, 31].includes(Number(day))) ex = 'st'
    else if ([2, 22].includes(Number(day))) ex = 'nd'
    else if ([3, 23].includes(Number(day))) ex = 'rd'
    else ex = 'th'

    const blogEntryDate = new Date(date)

    let hours = blogEntryDate.getHours()
    const minutes = blogEntryDate.getMinutes()

    let period = 'AM';
    if (hours >= 12) period = 'PM'
    if(hours > 12) hours -= 12
    if(hours === 0) hours = 12

    const returnDate = `${weekday}, ${month} ${String(Number(day))}${ex} ${year}, ${hours}:${minutes}${period}`

    return returnDate
}