// import { Banner } from './Banner'
import { LandingImage } from './LandingImage'
// import SecondImage from '../../assets/SecondImage.jpeg'
// import { Fade } from './Fade'
// import { useContext } from 'react'
// import { GlobalContext } from '../../GlobalContext'
import { LandingAnimation } from './LandingAnimation'
import { ThirdImage } from './ThirdImage'
// import { NavButtons } from './NavButtons'

import { useEffect } from "react";
import { useLocation } from "react-router";

export const Home = () => {
    
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const LandingImageCaption = () => <div className='desktop-home-landing-caption fancy-font'><h3>Blessed is the one who bequeaths a good name to his descendants</h3></div>
    
    return (
        <div className="desktop-home appflex">
            <LandingImage />
            <LandingImageCaption />
            <ThirdImage />
            <LandingAnimation />
            <div style={{ marginBottom: '45px', fontStyle: 'italic', color: 'rgba(255,255,255,0.6)', textAlign: 'center', fontSize: '1.5rem'}}>"The idea is not to live forever, but to create something that will."<br/> - Andy Warhol</div>
        </div>
    )
}

// const { fadeOn } = useContext(GlobalContext)
// {/* {fadeOn ? <Fade /> : ''} */}
// {/* <img src={SecondImage} alt='landing' className='desktop-second-image home-image' /> */}