export const filterUpcomingPodcasts = (podcasts) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const today10am = new Date(today);
    today10am.setHours(10, 0, 0, 0);
  
    return podcasts.filter((podcast) => {
      const podcastDate = new Date(podcast.dateOfPodcast);
  
        if (podcastDate < today) {
            return true;
        } else if (podcastDate.getTime() === today.getTime() && now >= today10am) {
            return true;
        } 
        return false;
    })
}