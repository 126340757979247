export const Brand = ({ hideBottom }) => {
    return (
        <div className="m-brand-container">
            <div className="m-brand-text fancy-font">Soul Of The Watchman</div>
            {hideBottom ? <></> : (
                <>
                    <div className="m-brand-sub-text">Debra Briss, Inc.</div>
                    <div className="m-brand-sub-text">5th Generation Funeral Director</div>
                    <div className="m-brand-sub-text">Grief Processing Coach</div>
                </>
            )}
        </div>
    )
}