import { ServicesContext } from "../../Contexts/ServicesContext"
import { useContext } from "react"
import { Backdrop } from "@mui/material"
import { enablePageScroll } from 'scroll-lock'

export const ServiceBackdrop = () => {
    const { preparation, planning, nonTraditional, unveiling, grief, currentlyExpanded, closeMobile } = useContext(ServicesContext)
    const values = [preparation, planning, nonTraditional, unveiling, grief]

    let backdropValue = null

    if (currentlyExpanded) backdropValue = values.find(x => x.id === currentlyExpanded)
    
    const BackdropHeader = () => currentlyExpanded ? <div className='m-services-backdrop-header'>{backdropValue.title}</div> : <></>
    
    const BackdropBody = () => backdropValue ? (
        <div className="mobile-column m-services-backdrop-body-container">
            {backdropValue.expandedText.map((sec, i) => (
                <div key={`m-backdrop-body-item-${i}`} className='m-services-backdrop-body-item'>
                    {sec}
                </div>
            ))}
        </div>
    ) : <></>

    // const BackdropButton = () => backdropValue ? (
    //     <a href={`https://calendly.com/soulofthewatchman/${backdropValue.link}`} target="_blank" rel="noreferrer">
    //         <div className="m-services-button-link">{backdropValue.buttonText}</div>
    //     </a>
    // ) : <></>

    const CloseButton = () => {
        const handleClose = () => {
            enablePageScroll()
            closeMobile()
        }
        return <div className="m-services-close-button" onClick={handleClose}>Close</div>
    }

    return (
        <Backdrop open={currentlyExpanded ? true : false} style={{ zIndex: 100, height: '101vh', width: '100vw', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(0,0,0,0.8)'}} transitionDuration={0}>
            <div style={{ minHeight: '35%', width: '95%', backgroundColor: 'white', borderRadius: '8px', marginTop: '10vh', zIndex: 102 }} className='mobile-column'>
                <BackdropHeader />
                <BackdropBody />
                {/* <BackdropButton /> */}
                <CloseButton />
            </div>
        </Backdrop>
    )
}
//! figure out click away listener