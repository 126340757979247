import CircleIcon from '@mui/icons-material/Circle'
import { extractVideoID } from './helpers/ExtractYoutubeID'

const Header = ({ data }) => <div className="d-blog-header">{data.text}</div>
const Body = ({ data }) => <div className="d-blog-body">{data.text}</div>
const ListItem = ({ data }) => <div className="d-blog-list-item"><CircleIcon style={{ fontSize: '12px', marginRight: '5px' }} color="inherit" />{data.text}</div>
const Emphasis = ({ data }) => <div className="d-blog-emphasis fancy-font">{data.text}</div>
const Picture = ({ data, guest }) => <div className="d-blog-picture-container"><img src={guest ? data.url : URL.createObjectURL(data.value)} alt='preview for blog' className="d-blog-picture" /><div className="d-blog-picture-caption">{data.caption}</div></div>
const Youtube = ({ data }) => <div className="d-blog-youtube"><iframe style={{ margin: '0 auto' }} width="650" height="365" src={`https://www.youtube.com/embed/${extractVideoID(data.url)}`} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Embedded youtube" /></div>
const External = ({ data }) => {
    console.log(data)
    console.log('url?', data.url)
    return <a className="d-blog-external-link" href={data.url} target='_blank' rel="noreferrer">{data.linkText}</a>
}

export const ContentRouter = ({ data, guest }) => {
    if (data.deleted) return <></>
    else if (data.type === 'header') return <Header data={data} />
    else if (data.type === 'body') return <Body data={data} />
    else if (data.type === 'list-item') return <ListItem data={data} />
    else if (data.type === 'emphasis') return <Emphasis data={data} />
    else if (data.type === 'picture') return <Picture data={data} guest={guest} />
    else if (data.type === 'youtube') return <Youtube data={data} />
    else if (data.type === 'external') return <External data={data} />
    else return <>Error</>
}