
export const MissionStatementMobile = () => {
    return (
        <div className="m-mission-statement-container">
            <div className="m-mission-statement-section">
                Our family has served the Jewish community for over 129 years. Jacob H. Levine
                served as the Shomer (watchman) for the Fowler Street Shul, and in 1893 began
                Levine Chapels when he was 37 years old. We have a wealth of experience and a
                depth of knowledge in helping families navigate the complexities in the decision-
                making process at the most difficult times in one's life. For more than 129 years, we have been helping families
                before, during and after they suffer a loss.
            </div>
            <br/>
            <div className="m-mission-statement-section">
                This site was created from long-standing belief it is our sacred duty to serve. We
                meet people in their hour of need, we meet people where they are. Creating a platform 
                built on the core values that Jacob's parents Boona Simcha and Samuel Levine set forth 
                for him, and that Jacob exemplified for us his children since 1893, we carry on.
            </div>
            <br/>
            <div className="m-mission-statement-section">
                “It is our job to listen, everyone has a story to tell.” Philip Briss
            </div>
            <br/>
            <div className="m-mission-statement-section">
                It is our job to listen, to guide, and to create a safe space to help families honor
                what is meaningful to them, with our expertise, through creativity, patience and
                compassion. We are introducing the Levine family's 7th generation, with
                Hannah R. Wolfe and her sister Sophie L. Wolfe who are both taking seats on the Board of
                Directors. This site captures the vast knowledge and creative talents of each of us.
                Bringing our best selves in service to our community.
            </div>
        </div>
    )
}