import { Link } from "react-router-dom"
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { useState, useContext } from "react"
import { GlobalContext } from "../../GlobalContext"

import HomeIcon from '@mui/icons-material/Home';
// import HelpIcon from '@mui/icons-material/Help';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import CallIcon from '@mui/icons-material/Call';
import PeopleIcon from '@mui/icons-material/People';

export const NavBar = () => {

    const { theme: { primary } } = useContext(GlobalContext)

    const [value, setValue] = useState(0);

    const mobileNavigationItems = [
        { id: 1, to: '/', label: 'Home', icon: <HomeIcon />},
        { id: 2, to: '/about', label: 'Our Family', icon: <PeopleIcon />},
        { id: 3, to: '/services', label: 'Services', icon: <MiscellaneousServicesIcon />},
        { id: 4, to: '/contact', label: 'Contact', icon: <CallIcon />},
    ]
    return (
        <div className="m-nav-container">
            <div className="m-mobile-nav-fade"></div>
            <BottomNavigation
                sx={{ width: '100vw', height: '7vh', backgroundColor: 'white' }} 
                // showLabels
                value={value}
                onChange={(e, newValue) => setValue(newValue)}>
                {mobileNavigationItems.map(n => (
                    <BottomNavigationAction
                        key={`mobile-nav-item-${n.id}`}
                        style={{ height: '100%', color: primary }} 
                        component={Link}
                        to={n.to} 
                        label={n.label} 
                        icon={n.icon} 
                    />
                ))}
            </BottomNavigation>
        </div>
    )
}