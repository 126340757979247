import { forwardRef, useState } from "react"
import { TextField } from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle'
import ClearIcon from '@mui/icons-material/Clear'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'


const contentTypes = [
    {
        key: '',
        type: 'body',
        text: '',
        deleted: false
    },{
        key: '',
        type: 'header',
        text: '',
        deleted: false
    },{
        key: '',
        type: 'list-item',
        text: '',
        deleted: false
    },{
        key: '',
        type: 'emphasis',
        text: '',
        deleted: false
    },{
        key: '',
        type: 'picture',
        caption: '',
        value: null,
        deleted: false
    },{
        key: '',
        type: 'youtube',
        url: '',
        deleted: false
    },{
        key: '',
        type: 'external',
        url: '',
        linkText: '',
        deleted: false
    }
]

export const generateContentType = (type, length) => {
    const newContentObject = { ...contentTypes.find(x => x.type === type) }
    newContentObject.key = `blog-content-${type}-${length}`
    return newContentObject
}

const textfieldStyle = { width: '100%' }


const DeleteButton = ({ contentKey, deleteContent, deleteItemFromState }) => {

    const handleDelete = () => {
        deleteItemFromState(contentKey)
        deleteContent(contentKey)
    }

    return (
        <div className="d-blog-delete-content-button-container">
            <div className="d-blog-delete-content-button" onClick={handleDelete}>
                <ClearIcon style={{ color: 'inherit', fontSize: '30px' }} />
            </div>
        </div>
    )
}

const Header = forwardRef(({ item: { deleted, key, text }, primary, writeToContent, deleteContent, deleteItemFromState }, ref) => {
    if (deleted) return <></>
    return (
        <div className="d-blog-list-item-create-row">
            <TextField 
                onChange={e => writeToContent(key, 'text', e.target.value)}
                style={textfieldStyle}
                label='Header'
                color='primary'
                defaultValue={text}
                InputLabelProps={{ style: { fontWeight: 'bold' }}}
                inputProps={{ style: { fontWeight: 'bold', color: primary }}}
            />
            <DeleteButton contentKey={key} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState} />
        </div>
    )
})
const Body = forwardRef(({ item: { deleted, key, text }, primary, writeToContent, deleteContent, deleteItemFromState }, ref) => {
    if (deleted) return <></>
    return (
        <div className="d-blog-list-item-create-row">
            <TextField 
                onChange={e => writeToContent(key, 'text', e.target.value)}
                defaultValue={text}
                style={textfieldStyle}
                label='Body Text'
                color='primary'
                multiline
                maxRows={5}
                inputProps={{ style: { color: primary }}}
            />
            <DeleteButton contentKey={key} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState}/>
        </div>
    )
})
const ListItem = forwardRef(({ item: { deleted, key, text }, primary, writeToContent, deleteContent, deleteItemFromState }, ref) => {
    if (deleted) return <></>
    return (
        <div className="d-blog-list-item-create-row">
            <CircleIcon color='primary' style={{ marginRight: '8px' }} />
            <TextField 
                defaultValue={text}
                onChange={e => writeToContent(key, 'text', e.target.value)}
                style={textfieldStyle}
                label='List Item'
                color='primary'
                inputProps={{ style: { color: primary }}}
            />
            <DeleteButton contentKey={key} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState}/>
        </div>
    )
})
const Emphasis = forwardRef(({ item: { deleted, key, text }, primary, writeToContent, deleteContent, deleteItemFromState }, ref) => {
    if (deleted) return <></>
    return (
        <div className="d-blog-list-item-create-row">
            <TextField 
                defaultValue={text}
                onChange={e => writeToContent(key, 'text', e.target.value)}
                style={textfieldStyle}
                label='Emphasis'
                color='primary'
                className="fancy-font"
                InputLabelProps={{ style: { fontWeight: 'bold', color: primary, fontFamily: 'Square Peg', fontSize: '26px', letterSpacing: '1.3px' }}}
                inputProps={{ style: { fontWeight: 'bold', color: primary, fontFamily: 'Square Peg', fontSize: '26px', letterSpacing: '1.3px' }}}
            />
            <DeleteButton contentKey={key} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState}/>
        </div>
    )
})
const Picture = forwardRef(({ item: { deleted, key, caption, value }, primary, writeToContent, deleteContent, deleteItemFromState }, ref) => {
    const [imageAttached, setImageAttached] = useState(value ? URL.createObjectURL(value) : null)
    if (deleted) return <></>

    const TheImage = () => Boolean(imageAttached) ? <div className="d-blog-image-container"><img src={URL.createObjectURL(value)} alt='preview for blog' className="d-blog-image-preview" /></div> : <div className="d-blog-image-container"></div>

    const handleAttachImage = e => {
        ref.current.content.find(x => x.key === key).value = e.target.files[0]
        setImageAttached(URL.createObjectURL(e.target.files[0]))
    }

    return (
        <div className="d-blog-list-item-create-row">
            <label htmlFor={`attachImageContent-${key}`}>
                <div className="d-blog-upload-image-content-item">
                    <AddAPhotoIcon color='inherit' fontSize="inherit"/>
                    <input onChange={handleAttachImage} accept="image/*" type='file' id={`attachImageContent-${key}`} style={{ position: 'absolute', display: 'none', cursor: 'pointer', fontSize: '30px' }}/>
                </div>
            </label>
            <TheImage />
            <TextField 
                defaultValue={caption}
                onChange={e => writeToContent(key, 'caption', e.target.value)}
                style={{ ...textfieldStyle, marginLeft: '20px'}}
                label='Caption (optional)'
                color='primary'
                placeholder="Here is a picture of a tree"
                inputProps={{ style: { color: primary }}}
            />
            <DeleteButton contentKey={key} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState}/>
        </div>
    )
})
const Youtube = forwardRef(({ item: { deleted, key, url }, primary, writeToContent, deleteContent, deleteItemFromState }, ref) => {
    if (deleted) return <></>
    return (
        <div className="d-blog-list-item-create-row">
            <TextField 
                defaultValue={url}
                onChange={e => writeToContent(key, 'url', e.target.value)}
                style={textfieldStyle}
                label='YouTube Video'
                placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                inputProps={{ style: { color: primary }}}
            />
            <DeleteButton contentKey={key} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState}/>
        </div>
    )
})
const External = forwardRef(({ item: { deleted, key, url, linkText }, primary, writeToContent, deleteContent, deleteItemFromState }, ref) => {
    if (deleted) return <></>
    return (
        <div className="d-blog-list-item-create-row">
            <TextField 
                defaultValue={url}
                onChange={e => writeToContent(key, 'url', e.target.value)}
                style={{ width: '50%', marginRight: '10px' }}
                label='External Link'
                placeholder="https://www.google.com/"
                inputProps={{ style: { color: primary }}}
            />
            <TextField 
                defaultValue={linkText}
                onChange={e => writeToContent(key, 'linkText', e.target.value)}
                style={{ width: '50%' }}
                label='Link Text (optional)'
                placeholder="Click here to go to Google"
                inputProps={{ style: { color: primary }}}
            />
            <DeleteButton contentKey={key} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState}/>
        </div>
    )
})

export const ContentSectionItem = forwardRef(( { item, primary, deleteContent, writeToContent, deleteItemFromState }, ref ) => {
    if (item.type === 'header') return <Header item={item} ref={ref} primary={primary} writeToContent={writeToContent} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState} />
    else if (item.type === 'body') return <Body item={item} ref={ref} primary={primary} writeToContent={writeToContent} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState} />
    else if (item.type === 'list-item') return <ListItem item={item} ref={ref} primary={primary} writeToContent={writeToContent} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState} />
    else if (item.type === 'emphasis') return <Emphasis item={item} ref={ref} primary={primary} writeToContent={writeToContent} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState} />
    else if (item.type === 'picture') return <Picture item={item} ref={ref} primary={primary} writeToContent={writeToContent} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState} />
    else if (item.type === 'youtube') return <Youtube item={item} ref={ref} primary={primary} writeToContent={writeToContent} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState} />
    else if (item.type === 'external') return <External item={item} ref={ref} primary={primary} writeToContent={writeToContent} deleteContent={deleteContent} deleteItemFromState={deleteItemFromState} />
    else return <></>
})