import '../App.css';
import { Routes, Route, Outlet } from "react-router-dom"
import { NavBar } from './NavBar/NavBar'

import { Error } from './UrlError/Error'
import { About } from './About/About'
import { Home } from './Home/Home'
import { Contact } from './Contact/Contact'
import { Services } from './Services/Services'
import { Information } from './Information/Information'
import { Footer } from './Home/Footer'
import { Blog } from './Blog/Blog'
import { Blogs } from './Blog/Blogs'
import { Podcasts } from './Podcast/Podcasts'
import { AuthContainer } from './Auth/AuthContainer'
import { Notifications } from './Notifications/Notifications'
import { Podcast } from './Podcast/Podcast'
// import { PodcastPreview } from './Podcast/PodcastPreview'

export const DesktopApp = () => {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="contact" element={<Contact />} />
                {/* <Route path="podcast" element={<PodcastPreview />} /> */}
                <Route path="podcast" element={<Podcasts />} />
                <Route path="podcast/:date" element={<Podcast />} />
                <Route path="blog" element={<Blogs />} />
                <Route path="blog/:id" element={<Blog />} />
                <Route path="services" element={<Services />} />
                <Route path="info" element={<Information />} />
                <Route path="*" element={<Error />} />
            </Route>
        </Routes>
    )
}
function Layout() {
    return (
        <div className='appflex'>
            <AuthContainer />
            <NavBar />
            <Outlet />
            <Footer />
            <Notifications />
        </div>
    )
}