import { NewBlogContent } from "./components/NewBlogContent"
import { useRef, useContext, useState } from 'react'
import { TextField } from "@mui/material"
import { GlobalContext } from '../../../../GlobalContext'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'

export const Create = ({ setRoute }) => {

    const { theme: { primary } } = useContext(GlobalContext)

    const newBlogRef = useRef({
        author: '',
        title: '',
        subtitle: '',
        thumbnail: null,
        content: []
    })

    const writeToContent = (key, field, value) => newBlogRef.current.content.find(x => x.key === key)[field] = value

    const deleteContent = key => newBlogRef.current.content.find(x => x.key === key).deleted = true

    //? Category (possibly, to filter relevant posts) 
    //! figure this out later

    const textfieldStyle = { width: '100%', margin: '5px 0' }
    const handleUpdateBlogDetail = e => newBlogRef.current[e.target.name] = e.target.value
    const Title = () => <TextField inputProps={{ style: { color: primary } }} style={textfieldStyle} color='primary' label='Title' placeholder="Blog Title" name='title' onChange={handleUpdateBlogDetail} />
    const SubTitle = () => <TextField inputProps={{ style: { color: primary } }} style={textfieldStyle} color='primary' label='Sub Title (optional)' placeholder="Blog SubTitle" name='subtitle' onChange={handleUpdateBlogDetail} />

    const Thumbnail = () => {
        const [thumbnailAttached, setThumbnailAttached] = useState(newBlogRef.current.thumbnail)
        const TheImage = () => thumbnailAttached ? <img src={thumbnailAttached} alt='preview for blog' className="d-blog-thumbnail-preview" /> : <></>
        const handleAttachThumbnail = e => {
            newBlogRef.current.thumbnail = e.target.files[0]
            setThumbnailAttached(URL.createObjectURL(e.target.files[0]))
        }
        return (
            <div className="d-blog-thumbnail-upload">
                <label htmlFor="attachImage">
                    <div className="d-a-c-p-thumbnail-upload-icon">
                        <AddAPhotoIcon color='inherit' fontSize="inherit"/>
                        <input onChange={handleAttachThumbnail} accept="image/*" type='file' id='attachImage' style={{ position: 'absolute', display: 'none', cursor: 'pointer', fontSize: '30px' }}/>
                    </div>
                </label>
                <TheImage />
            </div>
        )
    }

    const Main = () => (
        <div className='d-blog-create-main'>
            <div className="d-blog-create-main-left">
                <Title />
                <SubTitle />
            </div>
            <div className="d-blog-create-main-right">
                <Thumbnail />
            </div>
        </div>
    )

    const Author = () => {
        const [author, setAuthor] = useState(null)

        const authors = ['Debra Briss Wolfe', 'Hannah Wolfe', 'Sophie Wolfe']

        const selectedStyle = {
            backgroundColor: 'rgba(88, 83, 58, 0.9)',
            cursor: 'default',
            color: 'white'
        } 
        const unselectedStyle = {
            backgroundColor: 'white',
            cursor: 'pointer',
            color: 'rgba(88, 83, 58, 0.9)'
        }

        const handleSetAuthor = newAuth => {
            newBlogRef.current.author = newAuth
            setAuthor(newAuth)
        }

        return (
            <div className='d-blog-create-author-options'>
                {authors.map(auth => (
                    <div onClick={() => handleSetAuthor(auth)} className="d-blog-create-author-option" style={author === auth ? selectedStyle : unselectedStyle} key={`author-option-${auth.split(' ')[0]}`}>
                        {auth}
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className="d-blog-create-container">
            <Author />
            <Main />
            <NewBlogContent setRoute={setRoute} writeToContent={writeToContent} deleteContent={deleteContent} ref={newBlogRef} primary={primary} />
        </div>
    )
}