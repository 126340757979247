import { useParams } from 'react-router-dom'
import { useContext, useEffect, useState, useRef } from 'react'
import { GlobalContext } from '../../GlobalContext'
import AudioPlayer from 'react-h5-audio-player'
import { collection, query, where, getDocs } from "firebase/firestore"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import CircularProgress from '@mui/material/CircularProgress'
import 'react-h5-audio-player/lib/styles.css'
import { Link } from 'react-router-dom'

export const Podcast = () => {
    const { podcasts: { data }, database: { db } } = useContext(GlobalContext)

    const playerRef = useRef(null)

    const podcastRef = useRef({ info: null, audio: null, thumbnail: null, loaded: false, preload: null, started: false })

    const [loadedPodcast, setLoadedPodcast] = useState(false) //! false
    const [error, setError] = useState(false)
    const { date } = useParams()

    useEffect(() => {
        if (!playerRef.current) return
        const checkForPreviousProgress = localStorage.getItem(date)
        if (checkForPreviousProgress) playerRef.current.audio.current.currentTime = checkForPreviousProgress
    }, [loadedPodcast, date])

    useEffect(() => {
        (async () => {
            
            const storage = getStorage()
            if (Boolean(data)) {
                const currentTimestamp = localStorage.getItem(date) || null
                if (currentTimestamp) podcastRef.current.preload = currentTimestamp
                const selectedPodcast = data.find(x => x.dateOfPodcast === date) || null

                podcastRef.current.info = { ...selectedPodcast }

                getDownloadURL(ref(storage, podcastRef.current.info.audioRoute))
                .then(url => podcastRef.current.audio = url)
                .then(() => {
                    getDownloadURL(ref(storage, podcastRef.current.info.thumbRoute))
                    .then(url => podcastRef.current.thumbnail = url)
                    .then(() => podcastRef.current.loaded = true)
                    .then(() => setLoadedPodcast(true))
                    .catch((error) => {
                        console.error(error)
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
            } else {
                if (!Boolean(db) || podcastRef.current.loaded) return
                const currentTimestamp = localStorage.getItem(date) || null
                if (currentTimestamp) podcastRef.current.preload = currentTimestamp
                const podcastDBRef = collection(db, "podcasts")
                const q = query(podcastDBRef, where("dateOfPodcast", "==", date))
                const querySnapshot = await getDocs(q)
                querySnapshot.forEach(doc => podcastRef.current.info = { ...doc.data(), id: doc.id })
                if (podcastRef.current.info === null) return setError(true)
                getDownloadURL(ref(storage, podcastRef.current.info.audioRoute))
                .then(url => podcastRef.current.audio = url)
                .then(() => {
                    getDownloadURL(ref(storage, podcastRef.current.info.thumbRoute))
                    .then(url => podcastRef.current.thumbnail = url)
                    .then(() => podcastRef.current.loaded = true)
                    .then(() => setLoadedPodcast(true))
                    .catch((error) => {
                        console.error(error)
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
            }
        })()
    })

    const PodcastBody = () => {
        const { title, description, dateOfPodcast } = podcastRef.current.info

        const PodcastInfo = () => (
            <div className='d-podcast-player-info-container'>
                <div className='d-podcast-player-date'>{dateOfPodcast}</div>
                <div className="d-podcast-player-info-header">{title}</div>
                <div className="d-podcast-player-info-body">{description}</div>
            </div>
        )

        const ExtraDetails = () => {
            const { guestNames, linkText, linkUrl } = podcastRef.current.info
            if (!guestNames && !linkText && !linkUrl) return <></>

            const GuestNames = () => (
                <div className='d-podcast-guest-name-container'>
                    Featuring: <span className="d-podcast-guest-name-decoration">{guestNames}</span>
                </div>
            )

            const PodcastLink = () => <a className='d-podcast-link-text' href={linkUrl} target='_blank' rel="noreferrer">{linkText || linkUrl}</a>

            return (
                <div className='d-podcast-extra-details-container'>
                    <GuestNames />
                    <PodcastLink />
                </div>
            )
        }

        const BackToAllPodcasts = () => <Link to='/podcast' className='d-podcasts-return-to-all'>Back to all episodes</Link>


        const handleListenProgress = () => {
            const progress = playerRef.current.audio.current.currentTime
            localStorage.setItem(date, progress)
        }
        const handleStart = () => {
            return
        }

        // const PodcastThumbnail = () => {


        //     return (
        //         <img src={podcastRef.current.thumbnail} alt='preview' className='d-podcast-thumbnail' />
        //     )
        // }

        return (
            <div className='d-podcast-player-container'>
                {/* <PodcastThumbnail /> */}
                <BackToAllPodcasts />
                <PodcastInfo />
                <AudioPlayer ref={playerRef} onPlay={handleStart} listenInterval={3500} onListen={handleListenProgress} progressJumpSteps={{ backward: 15000, forward: 15000 }} src={podcastRef.current.audio} />
                <ExtraDetails />
            </div>
        )
    }

    if (error) return (
        <div className="desktop-section-container">
            <div className="d-podcast-no-podcast-message">
                Incorrect podcast date
                <Link to='/podcast' className='d-podcasts-return'> Back to all episodes</Link>
            </div>
        </div>
    )

    return (
        <div className="desktop-section-container">
            <div className="d-podcasts-header fancy-font">The Funeral Dirge</div>
            {loadedPodcast ? <PodcastBody /> : (
                <div className='d-podcasts-loading-container'>
                <div className='d-podcasts-loading-feedback'>Loading Episode</div>
                <CircularProgress color='inherit' size={20} />
            </div>
            )}
        </div>
    )
}