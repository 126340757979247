import { servicesSections } from "../../Data/ServicesList"
import { useEffect } from "react"
import { useLocation } from "react-router"
import { ExpansionContextAdder } from "./ExpansionContextAdder"
// import { AllServicesButton } from "./AllServicesButton"
export const Services = () => {

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className="desktop-section-container">
            <div className="d-services-header-container">
                {servicesSections.map(section => <div className="d-services-section" key={`services-section-${section}`}>{section}</div>)}
            </div>
            <ExpansionContextAdder />
            {/* <AllServicesButton /> */}
        </div>
    )
}