import { Brand } from "./Brand"
import { LandingImage } from "./LandingImage"
import { SecondMobileImage } from './SecondMobileImage'
import { FamilyHistory } from './FamilyHistory'
import { FinalCaption } from './FinalCaption'

import { useEffect } from "react";
import { useLocation } from "react-router";

export const Home = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className="m-home-container">
            <Brand />
            <LandingImage />
            <SecondMobileImage />
            <FamilyHistory />
            <FinalCaption />
        </div>
    )
}