import { useState, forwardRef, useContext, useRef } from 'react'
import { generateContentType, ContentSectionItem } from './ContentTypes'
import AddIcon from '@mui/icons-material/Add'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import YouTubeIcon from '@mui/icons-material/YouTube'
import LinkIcon from '@mui/icons-material/Link'
import { GlobalContext } from '../../../../../GlobalContext'
import { SubmitButton } from './SubmitButton'

export const NewBlogContent = forwardRef(( { writeToContent, deleteContent, setRoute }, ref ) => {

    const { theme: { primary } } = useContext(GlobalContext)

    const scrollRefRef = useRef(null)


    const scrollToScrollRef = () => scrollRefRef.current.scrollIntoView({ behavior: 'smooth' })

    const [contentItems, setContentItems] = useState([])

    const deleteItemFromState = key => {
        setContentItems(s => {
            const tempObj = [...s]
            tempObj.find(x => x.key === key).deleted = true
            return tempObj
        })
    }

    const ControlPanel = () => {
    
        const [showingOptions, setShowingOptions] = useState(false)

        const toggleShowPanel = () => setShowingOptions(!showingOptions)

        if (showingOptions) {

            const buttonStyle = { marginRight: '3px' }

            const handleAddSection = type => {
                const newSection = generateContentType(type, contentItems.length)
                ref.current.content.push(newSection)
                setContentItems([ ...contentItems, newSection])
                setTimeout(() => scrollToScrollRef(), 25)
            }

            const HeaderButton = () => <div onClick={() => handleAddSection('header')} className='d-blog-panel-button d-blog-panel-header'>Header</div>
            const BodyButton = () => <div onClick={() => handleAddSection('body')} className='d-blog-panel-button d-blog-panel-body'>Body</div>
            const ListItemButton = () => <div onClick={() => handleAddSection('list-item')} className='d-blog-panel-button d-blog-panel-bullet'><FormatListBulletedIcon style={buttonStyle}/> Bullet</div>
            const EmphasisButton = () => <div onClick={() => handleAddSection('emphasis')} className='d-blog-panel-button fancy-font d-blog-panel-emphasis'>Emphasis</div>
            const PictureButton = () => <div onClick={() => handleAddSection('picture')} className='d-blog-panel-button'><InsertPhotoIcon style={buttonStyle}/> Picture</div>
            const YoutubeButton = () => <div onClick={() => handleAddSection('youtube')} className='d-blog-panel-button'><YouTubeIcon style={buttonStyle}/> YouTube</div>
            const ExternalLinkButton = () => <div onClick={() => handleAddSection('external')} className='d-blog-panel-button'><LinkIcon style={buttonStyle} /> Link</div>
            const CancelButton = () => <div className='d-blog-panel-button d-blog-panel-cancel' onClick={toggleShowPanel}>Cancel</div>

            return (
                <div className='d-blog-create-showing-sections'>
                    <HeaderButton />
                    <BodyButton />
                    <ListItemButton />
                    <EmphasisButton />
                    <PictureButton />
                    <YoutubeButton />
                    <ExternalLinkButton />
                    <CancelButton />
                </div>
            )
        } else {
            return (
                <div className='d-blog-control-panel-container'>
                    <div className='d-blog-create-add-section-icon' onClick={toggleShowPanel}>
                        <AddIcon color='inherit' fontSize='inherit' />
                    </div>
                    <SubmitButton ref={ref} setRoute={setRoute} />
                </div>  
            )
        }
    }

    const Content = () => {
        return (
            <div className='d-blog-create-content-section-container'>
                {contentItems.map(item => <ContentSectionItem key={item.key} item={item} ref={ref} primary={primary} writeToContent={writeToContent} deleteItemFromState={deleteItemFromState} deleteContent={deleteContent} />)}
            </div>
        )
    }

    const ScrollRef = () => {


        return <div ref={scrollRefRef}></div>
    }

    return (
        <div className='d-blog-create-content-container'>
            <Content />
            <ControlPanel />
            <ScrollRef />
        </div>
    )
})
