import { createContext, Component } from 'react'

export const ServicesContext = createContext()

export class ServicesContextProvider extends Component {
    constructor() {
        super()
        this.state = {           
            preparation: {
                id: 'preparation',
                title: 'I am preparing for a loss in my family?',
                type: '',
                expanded: false,
                expandedText: [
                    'Now what?',
                    'There are no scenarios that we are not capable of helping you through, even with an imminent death of a family member. We understand the importance of providing support mentally, and emotionally while you are processing the circumstances that you are in. Our goal is to gently guide you in creating a plan to honor them.'
                ],
                buttonText: 'Reserve an appointment here',
                link: 'preparation',
                fee: '$25',
                duration: '2 Hours'
            },
            planning: {
                id: 'planning',
                title: 'Why is planning my own funeral ahead of time a gift?',
                type: '',
                expanded: false,
                expandedText: [
                    'It shows love.',
                    'One of the most loving things we can do for our own loved ones is pre-planning our own services. By making the arrangements ahead of time, we can make a difficult time a little easier by already having the details communicated and arranged. We will work together to create the best way for your loved ones to process and commemorate your legacy.'
                ],
                buttonText: 'Reserve an appointment here',
                link: 'planning',
                fee: '$25',
                duration: '2 Hours'
            },
            nonTraditional: {
                id: 'nonTraditional',
                title: 'How do I plan a non-traditional service?',
                type: '',
                expanded: false,
                expandedText: [
                    'We have experience in helping people arrange all forms of services, regardless of traditional or non-traditional views. We are accepting of all people, religions, gender/non-gender identities, and communities. Non-traditional funeral services could include: cremation, green services, and memorial services.',
                ],
                buttonText: 'Reserve an appointment here',
                link: 'nonTraditional',
                fee: '$25',
                duration: '2 Hours'
            },
            unveiling: {
                id: 'unveiling',
                title: 'I need assistance in planning an Unveiling Ceremony',
                type: '',
                expanded: false,
                expandedText: [
                    'In the Jewish faith, the traditional practice of unveiling the monument or marker in memory of our loved one takes place a year after the funeral services. In our experience, helping family members locate the monument in the memorial park or cemetery has eased the stress of the day. We are here to help you and your community honor your loved one, and provide whatever support is needed throughout the planning process and the one-year anniversary.',
                ],
                buttonText: 'Reserve an appointment here',
                link: 'unveiling',
                fee: '$25',
                duration: '1 Hour'
            },
            grief: {
                id: 'grief',
                title: 'I need someone to talk to, aiding me through my grief and loss while assisting me in transitioning to a new normal in life',
                type: '',
                expanded: false,
                expandedText: [
                    'Dealing with grief is a unique and personal journey that we all experience in our own way. I understand that everybody’s needs are different, and that’s why I’m here to offer my support in any way I can. Whether it’s emotional support or practical assistance with daily tasks, my goal is to make sure you have everything you need to focus on your well-being during this difficult time.',
                ],
                buttonText: 'Reserve an appointment here',
                link: 'grief',
                fee: '$25',
                duration: '1 Hour'
            },
            currentlyExpanded: null,
            openMobile: section => this.setState({ currentlyExpanded: section }),
            closeMobile: () => this.setState({ currentlyExpanded: null }),
            toggle: (section, prev = null) => this.setState(s => {                
                if (!prev) {
                    s.currentlyExpanded = section
                    return s[section].expanded = true
                } else if (prev === section) {
                    s.currentlyExpanded = null
                    return s[section].expanded = false
                } else {
                    s.currentlyExpanded = section
                    s[prev].expanded = false
                    return s[section].expanded = true
                }
            })
        }
    }

    render() {
        return (
            <ServicesContext.Provider value={this.state}>
                {this.props.children}
            </ServicesContext.Provider>
        )
    }
}