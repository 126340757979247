import { servicesSections } from "../../Data/ServicesList"
import { Brand } from "../Home/Brand"
import { useEffect } from "react"
import { useLocation } from "react-router"
import { BackdropContextAdder } from "./BackdropContextAdder"
// import { AllServicesButton } from "../../Desktop/Services/AllServicesButton"

export const Services = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className="m-services-container mobile-column">
            <Brand hideBottom={true}/>
            <div className="m-services-header">Services</div>
            <div className="m-services-header-container mobile-column">
                {servicesSections.map((section, k) => <div className="m-services-section" key={`services-section-${k}`}>{section}</div>)}
            </div>
            <BackdropContextAdder />
            {/* <AllServicesButton /> */}
        </div>
    )
}