import RemoveIcon from '@mui/icons-material/Remove'
import Jacob from '../../assets/animation/1Jacob.jpg'
import Sam from '../../assets/animation/2Sam.jpg'
import Philip from '../../assets/animation/3Philip.jpg'
import Debra from '../../assets/animation/4Debra.jpg'

export const FamilyHistory = () => {
    const familyItems = [
        {
            picture: Jacob,
            name: 'Jacob H. Levine',
            dates: '1858 - 1944',
            header: '1858'
        },
        {
            picture: Sam,
            name: 'Sam “The Chief” Levine',
            dates: '1894 - 1983',
            header: '1894'
        },
        {
            picture: Philip,
            name: 'Philip Briss',
            dates: '1917 - 2004',
            header: '1917'
        },
        {
            picture: Debra,
            name: 'Debra Briss Wolfe',
            dates: '1969 -',
            header: '1969'
        }
    ]


    return (
        <div className='m-home-history'>
            {familyItems.map((item, i) => (
                <div className='m-home-family-history-container mobile-column' key={`family-item-${i}`}>
                    <div className="m-home-family-header fancy-font"><RemoveIcon />{item.header}<RemoveIcon /></div>
                    <div className="m-history-content-container">
                        <img src={item.picture} alt="Jacob" className='m-home-history-image home-image' />
                        <div className="m-history-name">{item.name}</div>
                        <div className="m-history-date">{item.dates}</div>
                    </div>
                </div>
            ))}
        </div>
    )
}