import { useState } from "react"
import { AuthHome } from "./Pages/AuthHome"
import { Create as CreatePodcast } from "./Pages/Podcasts/Create"
import { All as AllPodcasts } from "./Pages/Podcasts/All"
import { Create as CreateBlog } from "./Pages/Blog/Create"
import { All as AllBlogs } from './Pages/Blog/All'
// import { EditProfile } from "./Pages/EditProfile"
import { CreateSuccess as PodcastCreateSuccess } from "./Pages/Podcasts/CreateSuccess"

export const AdminConsole = () => {

    const [adminRoute, setRoute] = useState('Home')    

    const authRoutes = [
        { header: 'Admin Console', name: 'Home', component: <AuthHome setRoute={setRoute} />},
        { header: 'Podcasts - Create New', name: 'Create New Podcast', component: <CreatePodcast setRoute={setRoute} />},
        { header: 'Podcasts - Created New', name: 'Created New Podcast', component: <PodcastCreateSuccess setRoute={setRoute} />},
        { header: 'Podcasts - View All', name: 'View Podcasts', component: <AllPodcasts setRoute={setRoute} />},
        { header: 'Blog - Create New', name: 'Create New Blog Post', component: <CreateBlog setRoute={setRoute} />},
        { header: 'Blog - View All', name: 'View All Blog Posts', component: <AllBlogs setRoute={setRoute} />},
        // { header: 'Edit Contributor Profile', name: 'Edit Your Profile', component: <EditProfile setRoute={setRoute} />}
    ]
    const adminContent = authRoutes.find(x => x.name === adminRoute).component, adminHeader = authRoutes.find(x => x.name === adminRoute).header

    const BackToHomeButton = () => adminRoute === 'Home' ? <></> : <div className="d-admin-back-to-home-button" onClick={() => setRoute('Home')}>Back to console</div>

    return (
        <div className="d-auth-profile-container">
            <div className="d-auth-admin-header">
                {adminHeader}
                <BackToHomeButton />
            </div>
            {adminContent}
        </div>
    )
}