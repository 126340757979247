import { Bios } from "../../Data/Bios"

export const AboutBios = () => (
    <div className="d-bios-container">
        {Bios.map((bio, index) => (
            <div className="d-bio-container d-about-style" style={{ flexDirection: index % 2 === 0 ? 'row' : 'row-reverse' }} key={`about-item-${bio.id}`}>
                <div className="d-bio-character">
                    <img className="d-bio-pic-placeholder" src={bio.image} alt={bio.name}/>
                    <div className="d-bio-name">{bio.name}</div>
                    <div className="d-bio-title">{bio.title}</div>
                </div>
                <div className="d-bio-content">{bio.bio}</div>
            </div>
        ))}
    </div>
)