import Debbie from './BioPics/Debbie.png'
import Ann from './BioPics/Ann.png'
import Barry from './BioPics/Barry.png'
import David from './BioPics/David.png'
import Hannah from './BioPics/Hannah.png'
import Sophie from './BioPics/Sophie.png'

export const Bios = [
    {
        id: 111,
        name: 'Debra Briss Wolfe',
        title: 'President - Founder',
        bio: 'Debra has served the Jewish Community as a Funeral Director for the last 30 years. Debra began in 1990 serving her apprenticeship during the day and going to school in the evenings graduating in 1992 and joining her grandfather Philip Briss at Levine-Briss Funeral Home in Randolph, MA. She learned all aspects of the funeral business from him. Debra has devoted her work in the funeral industry, even while taking time to raise a family, and follow some personal interests. Studying yoga, spending a year working in one the largest cemeteries in the Northeast. Expanding her knowledge in different areas as well as building personal and professional relationships along the way. She holds several different certifications in a wide variety of fields which has offered her a unique pathway to helping the families she works with. Currently Debra is working towards advance degrees in the study of Psychology.',
        image: Debbie
    },
    {
        id: 222,
        name: 'Dr. Barry S. Briss DMD',
        title: 'Creative Director',
        bio: 'Barry is the first great grandchild of Jacob H. Levine and is named for Jacob\'s mother. He was raised and educated in Boston. Deciding on a different path by becoming an Orthodontist, having graduated from Tufts University School of Dental Medicine with a D.M.D. Eventually, he served as Chairman of that very same Orthodontic program for more than 15 years. During his tenure he served as President of The Eastern Component of the Edward H. Angle Society and the American Board of Orthodontics. Prior to his time as Chairman at Tufts Dr. Briss maintained a private practice for close to 28 years until 1998 when his son David assumed the position. Shortly after his retirement from full time academics in 2014 Barry joined the faculty at the Harvard School of Dental Medicine as a lecturer in the Department of Developmental Biology. He served in the U.S Navy Dental Corp and has received numerous honors in recognition for his service to his profession and to education. When Barry isn\'t teaching, he may be found golfing, writing novels, or painting.',
        image: Barry
    },
    {
        id: 333,
        name: 'Hannah R. Wolfe',
        title: 'Production Director',
        bio: 'Hannah is a journalist and recent graduate of Lasell University. A voracious reader Hannah currently works as the Used Books Assistant at Harvard Book Store, having previously served as a Bookseller. For the past five years Hannah has owned and operated her own website, The Rock N\' Roll Diary, as it is currently known. It is there where she shares essays and feature stories about live music, literature and culture from around the world and the United States. An award-winning artist and writer. She has worked at iHeartRadio as a Production Assistant, hosted her own Radio shows and podcasts, volunteered to register young voters with HeadCount and worked with the Villagers in the IT Department while attending school.',
        image: Hannah
    },
    {
        id: 444,
        name: 'Ann E. Briss',
        title: 'Director of Operations',
        bio: 'Ann grew up in Coral Gables Florida but came up to Boston to go to school at Forsyth School of Dental Hygiene. Graduating in 1963, she went home to Florida for one summer only to move up to Boston for good after that. Ann worked as a hygienist for a couple of years prior to getting married. She was deeply involved in Hadassah and Sisterhood of Temple Beth-El in Lowell. Soon becoming the office Manager for Barry S. Briss Orthodontics for 16 years. The office ran with a unique synergy. While Ann was involved in the practice, she was also raising a family.',
        image: Ann
    },
    {
        id: 666,
        name: 'Sophie Wolfe',
        title: 'Social Media Coordinator',
        bio: 'Sophie Wolfe is a junior, majoring in Psychology at Bridgewater State University. Sophie has a goal of becoming a school assessment counselor at either the high school or college level where she can help students overcome problematic situations, be it physical or mental. She has always been a good communicator, caring friend, and has a unique ability to truly understand what others are going through based, in part, on her personal experiences. Sophie has held a number of part time jobs through school, was an outstanding dancer in her youth at Edge Studio of Dance in Canton, MA and A Dancers World in Sharon, MA where she was a regular member of the competition team that earned exceptionally high honors at various competitions throughout the Boston area. She splits her time between Bridgewater State and her home in Stoughton, MA',
        image: Sophie
    },

    //! PUT HER HERE
    {
        id: 555,
        name: 'Dr. David S. Briss, DMD, FRCDC',
        title: 'Contributor',
        bio: 'Dr. David S. Briss has been practicing orthodontics for more than 25 years. He entered his father’s practice in 1997 and one year later assumed full responsibility for the practice. During his time in private practice, he also served as Clinical Instructor of Orthodontics at Tufts, then as Clinical Associate Professor and Clinical Director in the Department of Orthodontics and Dentofacial Orthopedics, Boston University Henry M. Goldman School of Dental Medicine. In 2019, David moved to New Jersey upon being appointed Associate Professor and Assistant Director of Post Graduate Orthodontics, Department of Orthodontics, Rutgers Health, School of Dental Medicine. He was recently appointed as Program Director at Rutgers. He is a Fellow of the Royal College of Dentists of Canada where he is an examiner and serves as an examiner for the American Board of Orthodontics. David was recently elected Director of the Angle East Component and appointed as a Member of the Board of Directors of the Edward E. Angle Society of Orthodontics. His current role on the Board is National Compliance Officer for Continuing Education. David also finds time to serve as the Commander for the General Jimmy Stewart Composite Squadron of the Civil Air Patrol/USAF Auxiliary in Sayreville, New Jersey, currently at the rank of Major.',
        image: David
    }

]