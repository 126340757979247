import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import FaxIcon from '@mui/icons-material/Fax'
import { Brand } from '../Home/Brand'
import { OfficeHours } from './OfficeHours'

// const contactItemStyle = { fontSize: '40px', marginRight: '10px' },
// ContactItemsMobile = [
//     {
//         id: 111,
//         name: 'phone1',
//         label: 'Main Phone Number',
//         link: '',
//         text: '',
//         icon: <PhoneIcon style={contactItemStyle} />
//     },
//     {
//         id: 333,
//         name: 'phone2',
//         label: 'Cell Phone Number',
//         link: '',
//         text: '',
//         icon: <PhoneIcon style={contactItemStyle} />
//     },
//     {
//         id: 222,
//         name: 'fax',
//         label: 'Fax',
//         link: null,
//         text: '',
//         icon: <FaxIcon style={contactItemStyle} />
//     },
//     {
//         id: 444,
//         name: 'email',
//         label: 'Email',
//         link: '',
//         text: '',
//         icon: <EmailIcon style={contactItemStyle} />
//     }
// ]

export const Contact = () => {

    const NewMobileContact = () => {
    
        return (
            <div className='m-new-contact-section'>
                <div className="m-new-contact-header">Call to schedule <a className='m-new-contact-link' href='tel:1-617-691-7100'>1-617-691-7100</a> or email us at</div>
                <div className="m-new-contact-header"><a className='m-new-contact-link' href='mailto:brissgriefprocesscoach@debrabrissinc.com'>BrissGriefProcessCoach@DebraBrissInc.com</a></div>
            </div>
        )
    }

    return (
        <div className="m-contact-container mobile-column">
            <Brand />
            <NewMobileContact />

            {/* <div className="m-contact-section-container">
                {ContactItemsMobile.map((item, i) => (
                    <a href={item.link} className='m-contact-item-container' key={`mobile-contact-item-${i}`}>
                        {item.icon}
                        <div className="m-contact-item-text">
                            <div className="m-contact-item-label">{item.label}</div>
                            {item.text}
                        </div>
                    </a>
                ))}
            </div> */}
            {/* <OfficeHours /> */}
        </div>
    )
}