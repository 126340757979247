import '../../Style/Animation/Animation.css'
import Jacob from '../../assets/animation/1Jacob.jpg'
import Sam from '../../assets/animation/2Sam.jpg'
import Philip from '../../assets/animation/3Philip.jpg'
import Debra from '../../assets/animation/4Debra.jpg'

export const LandingAnimation = () => {
    return (
        <div className="desktop-landing-animation-container">
            <div className="animation-image-container jacob">
                <img src={Jacob} alt="Jacob" className='animation-image' />
                <div className="animation-image-name">Jacob H. Levine</div>
                <div className="animation-image-date">1858 - 1944</div>
            </div>
            <div className="animation-image-container sam">
                <img src={Sam} alt="Sam" className='animation-image' />
                <div className="animation-image-name">Sam “The Chief” Levine</div>
                <div className="animation-image-date">1894 - 1983</div>
            </div>
            <div className="animation-image-container philip">
                <img src={Philip} alt="Philip" className='animation-image' />
                <div className="animation-image-name">Philip Briss</div>
                <div className="animation-image-date">1917 - 2004</div>
            </div>
            <div className="animation-image-container debra">
                <img src={Debra} alt="Debra" className='animation-image' />
                <div className="animation-image-name">Debra Briss Wolfe</div>
                <div className="animation-image-date">1969 -</div>
            </div>
        </div>
    )
}

/* 0% Delay - have all 4 on screen */
/* 10% Fade out Sam and Philip */
/* 20% Move Jacob and Debra to middle */
/* 30% Delay - have 2 on screen */
/* 40% Fade out Jacob */
/* 50% Move Debra to middle */
/* 60% Delay Have Debra only on screen */
/* 70% Move Debra back to original place */
/* 80% Fade in others */
/* 100% 5s Delay at end */