// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram'
import PersonIcon from '@mui/icons-material/Person'
import { useContext } from 'react'
import { GlobalContext } from '../../GlobalContext'

const iconStyle = { fontSize: '40px' }


const socialMedias = [
    // {
        //     id: 1111,
        //     name: 'LinkedIn',
        //     link: 'https://www.linkedin.com/in/debra-wolfe-87a508243/',
        //     icon : <LinkedInIcon color='inherit' style={iconStyle}/>
        // },
        {
            id: 2222,
            name: 'Instagram',
            link: 'https://www.instagram.com/soulofthewatchmanhq/',
            icon : <InstagramIcon color='inherit' style={iconStyle}  />
        }
    ]
    
    
    
export const SocialMedias = () => {
    const { user: { showAuth } } = useContext(GlobalContext)

    return (
        <div className="nav-social-media-container">
            {socialMedias.map(media => (
                <a href={media.link} target='_blank' rel="noreferrer" className='d-social-media-link' key={`social-media-${media.id}`}>
                    {media.icon}
                </a>
            ))}
            <div className="d-social-media-link" onClick={() => showAuth()}>
                <PersonIcon color='inherit' style={iconStyle} />
            </div>
        </div>
    )
}