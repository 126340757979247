import '../App.css'
import { Routes, Route, Outlet } from "react-router-dom"
import { NavBar } from './NavBar/NavBar'

import { Error } from './UrlError/Error'
import { About } from './About/About'
import { Home } from './Home/Home'
import { Contact } from './Contact/Contact'
import { Services } from './Services/Services'
import { MobileFooter } from './Home/MobileFooter'
export const MobileApp = () => {

    // console.log('mounted')
    // if (true) return (
    //     <div style={{ color: 'white', height: '100%', textAlign: 'center', width: '80%', margin: '300px auto', fontSize: '3rem'}} className='fancy-font'>Please use desktop for the full experience</div>
    // )

    return (
        <div>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="services" element={<Services />} />
                    <Route path="*" element={<Error />} />
                </Route>
            </Routes>
        </div>
    )
}
function Layout() {
    return (
        <div className='mobile-app'>
            <NavBar />
            <Outlet />
            <MobileFooter />
        </div>
    )
}