export const formatContentType = item => {
    let returnObj = {}

    if (['body', 'header', 'list-item', 'emphasis'].includes(item.type)) {
        returnObj = {
            key: item.key,
            type: item.type,
            text: item.text,
            order: item.order
        }
    } else if (item.type === 'picture') {
        returnObj = {
            order: item.order,
            key: item.key,
            type: item.type,
            caption: item.caption,
            imageId: item.imageId,
            pathToImage: item.pathToImage,
        }
    } else if (item.type === 'youtube') {
        returnObj = {
            order: item.order,
            key: item.key,
            type: item.type,
            url: item.url,
        }
    } else if (item.type === 'external') {
        returnObj = {
            order: item.order,
            key: item.key,
            type: item.type,
            url: item.url,
            linkText: item.linkText
        }
    } else {
        console.log('formatContentType Error')
    }
    return returnObj
}