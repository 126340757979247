// import ThirdLandingImage from '../../assets/ThirdImage.jpeg'
import SecondImage from '../../assets/SecondImage.jpeg'

export const ThirdImage = () => {
    return (
        <div className="desktop-landing-third-image">
            <img src={SecondImage} alt='landing' className='desktop-landing-third-image-image home-image' />
            <div className='third-image-content'>
                <div className="third-image-body">
                    “Infuse your life with action. Don't wait for it to happen.
                    Make it happen. Make your own future. Make your own
                    hope. Make your own love. And whatever your beliefs,
                    honor your beliefs, honor your creator, not by passively
                    waiting for grace to come down from upon high, but by
                    doing what you can to make grace happen…yourself, right
                    now, right down here on Earth.”
                </div>
                <div className="third-image-author">
                    -Bradley Whitford
                </div>
                <div>
                    Submitted by Dr. David S. Briss, DMD, FRCDC
                    <div className='third-image-qualifications'>
                        Clinical Director, <br/>
                        Department of Orthodontics<br/>
                        Rutgers School of Dental Medicine
                    </div>
                </div>
            </div>
        </div>
    )
}