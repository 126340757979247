// import { Notifications } from "../Notifications/Notifications"
import { NavLink } from "react-router-dom"
import { SocialMedias } from './SocialMedias.js'
// import { useState } from "react"

export const NavBar = () => {
    
    const navItems = [
        { link: "/", text: 'Home' },
        { link: "/about", text: 'Our Family' },
        { link: "/podcast", text: 'Podcast' },
        { link: "/services", text: 'Services' },
        { link: "/contact", text: 'Contact' },
        // { link: "/blog", text: 'Blog' }
    ]

    // const [notification, setNotification] = useState(null)
    

    return (
        <nav className="desktop-navbar">
            {/* <Notifications message={notification} /> */}
            <div className="desktop-navbar-top">
                <div className="desktop-navbar-top-row">
                    <NavLink to='/' style={{ textDecoration: 'none'}}>
                        <h1 className="initial-brand fancy-font">
                            Soul Of The Watchman
                        </h1>
                    </NavLink>
                    <div className="desktop-navbar-contact-items">
                        <div style={{ fontWeight: 'bold', letterSpacing: 1.1, fontSize: '1.1rem' }}>Debra Briss, Inc.</div>
                        <div style={{ marginTop: '2px', letterSpacing: 1.1, fontWeight: 'bold', fontSize: '1.1rem' }}>5th Generation Funeral Director</div>
                        <div style={{ marginTop: '2px', letterSpacing: 1.1, fontWeight: 'bold', fontSize: '1.1rem' }}>Grief Processing Coach</div>
                    </div>
                    <SocialMedias />
                </div>
                <div className="desktop-nav-buttons">
                    {navItems.map(item => <NavLink to={item.link} key={`nav-bar-item-${item.text}`} className='nav-item'>{item.text}</NavLink>)}
                </div>
            </div>
            <div className="desktop-navbar-bottom"></div>
        </nav>
    )
}