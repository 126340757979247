import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage"
import { collection, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore" 
import { useEffect, useState, useContext, useRef } from "react"
import { GlobalContext } from "../../../../GlobalContext"
import CircularProgress from '@mui/material/CircularProgress'
import EditIcon from '@mui/icons-material/Edit'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Backdrop, TextField } from "@mui/material"
import WarningIcon from '@mui/icons-material/Warning'
import CloseIcon from '@mui/icons-material/Close'
import ReactAudioPlayer from 'react-audio-player'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'

import AudioFileIcon from '@mui/icons-material/AudioFile'
import ImageIcon from '@mui/icons-material/Image'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'

export const All = () => {

    const { database: { db }, storage: { bucket } } = useContext(GlobalContext)

    const [podcastsState, setPodcastsState] = useState({ loading: true, data: null })
    const [warning, setWarning] = useState(false)
    const [editing, setEditing] = useState(null)

    const getAllPodcasts = async () => {
        const q = collection(db, "podcasts")
    
        const podcastsArr = []
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => podcastsArr.push({ ...doc.data(), id: doc.id } ))
        setPodcastsState({ data: podcastsArr, loading: false })
    }


    useEffect(() => {
        getAllPodcasts()
        // eslint-disable-next-line
    }, [])


    if (podcastsState.loading) {
        return (
            <div className="d-a-c-p-loading">
                <CircularProgress size={60} color='primary'/>
            </div>
        )
    }

    const months = ['January', 'February', 'March', 'April', 'May', 'June', ' July', 'August', 'September', 'October', 'November', 'December']

    const handleShowDeleteWarning = idToDelete => {

        setWarning(idToDelete)
    }

    const PodcastItem = ({ podcast }) => {
        const { title,
            // description,audioRoute,thumbRoute,
            dateOfPodcast,dateAdded,
            // linkUrl,linkText,guestNames,youtubeUrl,
            id } = podcast

        const numbers = dateOfPodcast.split('-')
        const year = numbers[0], month = Number(numbers[1]), day = Number(numbers[2])
        const iconSize = 65
        const fullMonth = months[month - 1]

        const postFix = num => {
            if ([1,21,31].includes(num)) return 'st'
            else if ([2, 22].includes(num)) return 'nd'
            else if ([3, 23].includes(num)) return 'rd'
            else return 'th'
        }

        const handleShowEditPanel = id => setEditing(podcastsState.data.find(x => x.id === id))

        return (
            <div className="d-a-c-individual-podcast-row">
                <div className="d-a-c-individual-podcast-row-left">
                    <div>{title}</div>
                </div>
                <div className="d-a-c-individual-podcast-row-middle">
                    <div className="d-a-c-individual-podcast-date">{fullMonth} {day}{postFix(day)}, {year}</div>
                    <div style={{ fontSize: '10px'}}>
                        Uploaded on:
                        <br />
                        {dateAdded}
                    </div>
                </div>
                <div className="d-a-c-individual-podcast-row-right">
                    <div className="d-a-c-individual-podcast-row-right-edit" onClick={() => handleShowEditPanel(id)}>
                        <EditIcon size={iconSize} color='inherit' fontSize='inherit' />
                    </div>
                    <div className="d-a-c-individual-podcast-row-right-delete" onClick={() => handleShowDeleteWarning(id)}>
                        <RemoveCircleIcon size={iconSize} fontSize='inherit' color='inherit' />
                    </div>
                </div>
            </div>
        )

    }

    const DeleteWarning = () => {

        const [deleting, setDeleting] = useState({ inProgress: false, completed: false, message: null })

        const handleCloseWarning = () => setWarning(false)

        const { title, description, dateOfPodcast, audioRoute, thumbRoute } = warning && !deleting.completed ? podcastsState.data.find(x => x.id === warning) : { title: null, description: null, dateOfPodcast: null }

        const handleDeletePodcast = () => {
            setDeleting(s => { 
                return { ...s, inProgress: true, message: 'Deleting Podcast from Firestore' }
            })

            deleteDoc(doc(db, "podcasts", warning))
            .then(() => {
                setDeleting(s => { 
                    return { ...s, message: 'Deleting Podcast Audio' }
                })
                const audioRef = ref(bucket, audioRoute);
                deleteObject(audioRef).then(() => {
                    setDeleting(s => { 
                        return { ...s, message: 'Deleting Podcast Thumbnail' }
                    })
                    const thumbRef = ref(bucket, thumbRoute)
                    deleteObject(thumbRef).then(() => {
                        setDeleting({ inProgress: false, completed: true, message: null })
                    })
                    .catch(e => {
                        console.error(e.code)
                        setDeleting(s => { 
                            return { ...s, message: 'Error While Deleting Podcast Thumbnail' }
                        })
                        setTimeout(() => {
                            setWarning(false)
                        }, 2000)
                    })
                }).catch(e => {
                    console.error(e.code)
                        setDeleting(s => { 
                            return { ...s, message: 'Error While Deleting Podcast Audio' }
                        })
                        setTimeout(() => {
                            setWarning(false)
                        }, 2000)
                })
            })
            .catch(e => {
                console.error(e.code)
                setDeleting(s => { 
                    return { ...s, message: 'Error While Deleting Podcast from Firestore' }
                })
                setTimeout(() => {
                    setWarning(false)
                }, 2000)
            })
        }

        const Deleting = () => {
            if (deleting.completed) return <></>
            return (
                <div className="d-a-c-delete-podcast-feedback">
                    <CircularProgress color='primary' style={{  }}/>
                    {deleting.inProgress ? <div className="d-a-c-delete-podcast-feedback-text">{deleting.message}</div> : 'Deleting'}
                </div>
            )
        }
        const Deleted = () => {
            (() => {
                setTimeout(() => {
                    setWarning(false)
                    setDeleting({ inProgress: false, completed: false, message: null })
                    setTimeout(() => {
                        getAllPodcasts()
                    }, 200)
                }, 3000)
            })()

            return (
                <div className="d-a-c-delete-podcast-feedback">
                    Podcast Deleted
                </div>
            )
        }

        const deleteHeader = deleting.inProgress ? 'Deleting...' : deleting.completed ? 'Delete Complete' : 'Delete Warning'

        return (
            <Backdrop style={{ zIndex: 50, backgroundColor: 'rgba(255,255,255,0.7)'}} open={Boolean(warning)}>
                <div className="d-a-c-delete-podcast-warning">
                    <div className="d-a-c-delete-podcast-warning-header">
                        <div className="d-a-c-delete-podcast-warning-header-brand">
                            <WarningIcon color='inherit' size="70px" style={{ marginRight: '8px'}}/>
                            {deleteHeader}
                        </div>
                        {deleting.inProgress ? <></> : (
                            <div className="d-a-c-delete-podcast-warning-header-close" onClick={handleCloseWarning}>
                                <CloseIcon color="inherit" style={{ fontSize: '30px' }} />
                            </div>
                        )}
                    </div>
                    {deleting.inProgress ? (
                        <div className="d-a-c-delete-podcast-warning-body">
                            <Deleting />
                        </div>
                    ) : deleting.completed ? (
                        <div className="d-a-c-delete-podcast-warning-body">
                            <Deleted />
                        </div>
                    ) : (
                        <div className="d-a-c-delete-podcast-warning-body">
                            <div className="d-a-c-delete-podcast-warning-body-top">Are you sure you wish to delete this podcast?</div>
                            <div>{title}</div>
                            <div>{description}</div>
                            <div>{dateOfPodcast}</div>
                            <div className="d-a-c-delete-podcast-warning-bottom">
                                <div onClick={handleDeletePodcast} className="d-a-c-delete-podcast-warning-delete">Delete</div>
                                <div onClick={handleCloseWarning} className="d-a-c-delete-podcast-warning-cancel">Cancel</div>
                            </div>
                        </div>
                    )}
                </div>
            </Backdrop>
        )
    }

    const EditPanel = () => {
        const editRef = useRef({ originalValue: null, newValue: null, detailName: null })

        const [editingScreen, setEditingScreen] = useState('Home'), //! Audio, Thumbnail, AllDetails, EditDetail, Success
        [updating, setUpdating] = useState(false),
        thumbnailRef = useRef(null), audioRef = useRef(null), successMessage = useRef('')

        const updateMediaItem = () => {
            setUpdating(true)
            if (editingScreen === 'Audio') {                
                const newAudioRef = ref(bucket, editing.audioRoute)

                uploadBytes(newAudioRef, audioRef.current).then(() => {
                    successMessage.current = 'Successfully updated Audio'
                    setEditingScreen('Success')
                    setUpdating(false)
                })
                .catch(e => {
                    setUpdating(false)
                    console.error(e.code)
                })


            } else if (editingScreen === 'Thumbnail') {
                const newThumbnailRef = ref(bucket, editing.thumbRoute)

                uploadBytes(newThumbnailRef, thumbnailRef.current).then(() => {
                    successMessage.current = 'Successfully updated thumbnail'
                    setEditingScreen('Success')
                    setUpdating(false)
                })
                .catch(e => {
                    setUpdating(false)
                    console.error(e.code)
                })
            }
        }
        const updateFirestoreItem = () => {
            setUpdating(true)

            const detailToUpdate = editRef.current.detailName, newDetailValue = editRef.current.newValue, docID = editing.id
            const podcastDocRef = doc(db, "podcasts", docID)

            updateDoc(podcastDocRef, {
                [detailToUpdate]: newDetailValue
            })
            .then(() => {
                successMessage.current = 'Successfully Updated Podcast'
                setUpdating(false)
                setEditingScreen('Success')
            })
            .catch(e => {
                setUpdating(false)
                console.error(e.code)
            })
        }

        const EditHeader = () => {
            const handleCloseEditPanel = () => {
                if (Boolean(successMessage.current)) return
                getAllPodcasts()
                setEditing(null)
                setEditingScreen('Home')
            }

            // console.log(editing)




            let headerMessage
            if (editingScreen === 'Home') headerMessage = 'Edit Podcast'
            else if (editingScreen === 'Audio') headerMessage = 'Replace Audio'
            else if (editingScreen === 'Thumbnail') headerMessage = 'Replace Thumbnail'
            else if (editingScreen === 'AllDetails') headerMessage = 'Select Detail'
            else if (editingScreen === 'EditDetail') headerMessage = `Edit ${editRef.current.headerText}`
            else if (editingScreen === 'Success') headerMessage = 'Changes Saved'

            return (
                <div className="d-a-c-p-edit-header">
                    {headerMessage}
                    {Boolean(successMessage.current) || updating ? <></> : (
                        <div onClick={handleCloseEditPanel} className="d-a-c-p-edit-exit">
                            <CloseIcon style={{ fontSize: 'inherit'}}/>
                        </div>
                    )}
                </div>
            )
        }

        const HomeScreen = () => {
            const itemStyle = { fontSize: '70px'}

            const menuItems = [
                { name: 'Audio', textBottom: 'Audio', textTop: 'Replace', icon: <AudioFileIcon style={itemStyle} />},
                { name: 'Thumbnail', textBottom: 'Thumbnail', textTop: 'Replace', icon: <ImageIcon style={itemStyle} />},
                { name: 'AllDetails', textBottom: 'Details', textTop: 'Podcast', icon: <FormatListBulletedIcon style={itemStyle} />}
            ]

            const editTitle = editing ? editing.title.slice(0, 35) : '', editDate = editing ? editing.dateOfPodcast : ''

            return (
                <div className="d-a-c-p-edit-home">
                    <div className="d-a-c-p-edit-home-header">
                        {editTitle}{editTitle.split('').length > 35 ? '...' : ''}
                        <br />
                        {editDate}
                    </div>
                    <div className="d-a-c-p-edit-home-buttons">
                        {menuItems.map(item => (
                            <div key={`edit-menu-item-${item.name}`} className='d-a-c-p-edit-home-button' onClick={() => setEditingScreen(item.name)}>
                                <div>{item.icon}</div>
                                <div>{item.textTop}</div>
                                <div>{item.textBottom}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )
        }

        const AudioScreen = () => {

            const inputRef = useRef(), [audioAttached, setAudioAttached] = useState(false)

            const url = audioAttached ? URL.createObjectURL(audioRef.current) : null

            const handleAttachAudio = e => {
                audioRef.current = e.target.files[0]
                setAudioAttached(true)
            }

            const AudioIsAttached = () => {
                if (!audioAttached) return <></>
    
                const handleRemoveAudio = () => {
                    audioRef.current = null
                    inputRef.current.value = null
                    setAudioAttached(false)
                }
    
                return (
                    <div className="d-a-c-p-audio-options">
                        <ReactAudioPlayer src={url} controls style={{ width: '100%' }} />
                        <div onClick={handleRemoveAudio} className="d-a-c-p-remove-audio-button">Remove Audio</div>
                    </div>
                )
            }

            return (
                <div className="d-a-c-p-audio-container">
                    <input accept="audio/*" ref={inputRef} type="file" id="temppodcastid" name="podcast" className="d-admin-create-podcast-input" onChange={handleAttachAudio} />
                    <AudioIsAttached />
                </div>
            )
        }
        const ThumbnailScreen = () => {
            const [thumbnailLoaded, setThumbnailLoaded] = useState(false)
            // const [updatingThumbnail, setUpdatingThumbnail] = useState(false)

            useEffect(() => {
                if (!thumbnailLoaded) {
                    getDownloadURL(ref(bucket, editing.thumbRoute))
                    .then(url => setThumbnailLoaded(url))
                    .catch(e => console.error(e.code));
                }
                // eslint-disable-next-line
            }, [])


            const [thumbnailAttached, setThumbnailAttached] = useState(false)

            const handleAttachThumbnail = e => {
                thumbnailRef.current = e.target.files[0]
                setThumbnailAttached(URL.createObjectURL(e.target.files[0]))
            }

    
            const TheImage = () => thumbnailAttached ? <img src={thumbnailAttached} alt='preview for podcast' className="d-a-c-p-thumbnail-preview" /> : thumbnailLoaded ? <img src={thumbnailLoaded} alt='preview for podcast' className="d-a-c-p-thumbnail-preview" /> : <></>
    
            return (
                <div className="d-a-c-p-thumbnail-upload">
                    <label htmlFor="attachImage">
                        <div className="d-a-c-p-thumbnail-upload-icon">
                            <AddAPhotoIcon color='inherit' fontSize="inherit"/>
                            <input onChange={handleAttachThumbnail} accept="image/*" type='file' id='attachImage' style={{ position: 'absolute', display: 'none', cursor: 'pointer', fontSize: '50px' }}/>
                        </div>
                    </label>
                    <TheImage />
                </div>
            )
        }
        const AllDetailsScreen = () => {

            const options = [
                { headerText: 'Title', detailName: 'title', text: 'Title', originalValue: editing.title },
                { headerText: 'Description', detailName: 'description', text: 'Description', originalValue: editing.description },
                { headerText: 'Date of Podcast', detailName: 'dateOfPodcast', text: 'Date Of Podcast', originalValue: editing.dateOfPodcast },
                { headerText: 'Guest Name(s)', detailName: 'guestNames', text: 'Guest Name(s)', originalValue: editing.guestNames },
                { headerText: 'Link Text', detailName: 'linkText', text: 'Link Text', originalValue: editing.linkText },
                { headerText: 'Link Url', detailName: 'linkUrl', text: 'Link URL', originalValue: editing.linkUrl },
                { headerText: 'Youtube Url', detailName: 'youtubeUrl', text: 'Youtube URL', originalValue: editing.youtubeUrl }
            ]

            const IndividualOptionButton = ({ text, detailName, originalValue, headerText }) => {

                const handleShowEditDetail = () => {
                    editRef.current = { originalValue, newValue: 'null', detailName, headerText }
                    setEditingScreen('EditDetail')
                }

                return (
                    <div onClick={handleShowEditDetail} className="d-a-c-p-edit-all-details-button">
                        {text}
                    </div>
                )
            }

            return (
                <div className="d-a-c-p-edit-all-details-list mobile-column">
                    {options.map(option => <IndividualOptionButton text={option.text} detailName={option.detailName} headerText={option.headerText} originalValue={option.originalValue} key={`individual-option-${option.detailName}`} />)}
                </div>
            )
        }

        const EditDetailScreen = () => {

            const { originalValue, detailName } = editRef.current

            if (detailName === 'dateOfPodcast') {
                const handleAttatchDate = e => editRef.current.newValue = e.target.value
                return <div><input type='date' defaultValue={originalValue} onChange={handleAttatchDate} className="d-a-c-p-date-picker-2" /></div>

            }

            const handleUpdateValue = e => {
                editRef.current.newValue = e.target.value
            }

            const label = [{ detailName: 'youtubeUrl', text: 'Youtube URL' }, { detailName: 'description', text: 'Description' }, { detailName: 'guestNames', text: 'Guest Name(s)' }, { detailName: 'linkText', text: 'Link Text' }, { detailName: 'linkUrl', text: 'Link URL' },  { detailName: 'title', text: 'Title' }].find(x => x.detailName === detailName).text

            return (
                <TextField 
                    defaultValue={originalValue}
                    type='text'
                    multiline
                    label={label}
                    onChange={handleUpdateValue}
                    maxRows={5}
                    color='primary'
                    style={{ width: '90%', margin: '20px auto 10px' }}
                />
            )
        }
        const SuccessScreen = () => {
            (() => {
                setTimeout(() => {
                    setEditingScreen('Home')
                    successMessage.current = ''


                    setEditing(s => {
                        return { ...s, [editRef.current.detailName]: editRef.current.newValue}
                    })

                }, 2000)
            })()
            
            return (
                <div className="d-a-c-p-edit-success-message">
                    {successMessage.current}
                </div>
            )
        }
        const EditFooter = () => {
            if (['Home', 'Success'].includes(editingScreen)) return <></>

            //! Figure this out if its worth it
            if (updating) return <CircularProgress color="primary" style={{ marginBottom: '24px' }}/>

            const handleSaveChanges = () => {
                if (['Audio', 'Thumbnail'].includes(editingScreen)) updateMediaItem()
                else updateFirestoreItem()
            }
            const handleReturnToHome = () => {
                setEditingScreen('Home')
            }
            const handleReturnToAllDetails = () => {
                setEditingScreen('AllDetails')   
            }

            return (
                <div className="d-a-c-p-edit-home-footer">
                    {editingScreen === 'AllDetails' ? <></> : <div onClick={handleSaveChanges} className="d-a-c-p-edit-footer-save">Save</div>}
                    {editingScreen === 'EditDetail' ? (
                        <div onClick={handleReturnToAllDetails} className="d-a-c-p-edit-footer-cancel">Back</div>
                    ) : (
                        <div onClick={handleReturnToHome} className="d-a-c-p-edit-footer-cancel">Back</div>
                    )}
                </div>
            )
        }
        

        let editScreens = [
            { name: 'Home', component: <HomeScreen /> },
            { name: 'Audio', component: <AudioScreen /> },
            { name: 'Thumbnail', component: <ThumbnailScreen /> },
            { name: 'AllDetails', component: <AllDetailsScreen /> },
            { name: 'EditDetail', component: <EditDetailScreen /> },
            { name: 'Success', component: <SuccessScreen /> }
        ]

        const editContent = editScreens.find(x => x.name === editingScreen).component

        return (
            <Backdrop open={Boolean(editing)} style={{zIndex: 50, backgroundColor: 'rgba(255,255,255,0.7)' }}>
                <div className="d-a-c-p-edit-container">
                    <EditHeader  />
                    {editContent}
                    <EditFooter />
                </div>
            </Backdrop>
        )
    }

    return (
        <div className="d-a-c-all-podcasts-scroll">
            <DeleteWarning />
            <EditPanel />
            {podcastsState.data.map((podcast, i) => <PodcastItem key={`individual-podcast-item-${i}`} podcast={podcast} /> )}
        </div>
    )
}