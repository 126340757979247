import { useContext } from 'react'
import { ServicesContext } from '../../Contexts/ServicesContext'
import { ServiceBackdrop } from './ServiceBackdrop'
import { disablePageScroll } from 'scroll-lock'

export const Backdrops = () => {

    const { preparation, openMobile, planning, nonTraditional, unveiling, grief } = useContext(ServicesContext)
    const order = [preparation, planning, nonTraditional, unveiling, grief]

    const handleOpenBackdrop = ser => {
        disablePageScroll()
        openMobile(ser)
    }

    return (
        <div className="m-services-backdrops-container mobile-column">
            <ServiceBackdrop />
            {order.map((service, i) => (
                <div className="m-services-backdrop-button" key={`m-service-button-${i}`} onClick={() => handleOpenBackdrop(service.id)}>
                    {service.title}
                </div>
            ))}
        </div>
    )
}