import { MissionStatement } from "./MissionStatement"
import { AboutBios } from './AboutBios'

import { useEffect } from "react";
import { useLocation } from "react-router";

export const About = () =>  {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className="desktop-section-container desktop-about-container">
            <MissionStatement />
            <AboutBios />
        </div>
    )
}
