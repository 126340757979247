import { TextField } from "@mui/material"
import { useState, useContext, useRef } from "react"
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth"
import { GlobalContext } from '../../GlobalContext'
import { doc, getDocs, collection, query, where, deleteDoc } from "firebase/firestore";

export const SignUp = ({ setRegistering }) => {

    const { user: { setData }, database: { db } } = useContext(GlobalContext)
    const [error, setError] = useState('')
    
    const handleCreateNewUser = async (email, password, codeToDelete) => {
        setError('')
        const auth = getAuth()
        createUserWithEmailAndPassword(auth, email, password)
        .then(userCredential => setData(userCredential.user))
        .then(() => deleteDoc(doc(db, "authCodes", codeToDelete)))
        // .then(() => ) //! Create document to be associated with this user
        .catch(error => setError(error.message))
    }

    const promptRef = useRef({ email: '', password: '', confirm: '', code: ''})
    const textfieldStyle= { width: '70%', margin: '5px auto' }

    const handleChange = e => promptRef.current[e.target.name] = e.target.value
    const handleCheck = async () => {
        if (!promptRef.current.email) setError('Please enter an email')
        else if (!promptRef.current.password) setError('Please enter an password')
        else if (!promptRef.current.confirm) setError('Please enter an confirm password')
        else if (!promptRef.current.code) setError('Please enter the sign in code you were given')
        else if (promptRef.current.password !== promptRef.current.confirm) setError('Password and confirm password do not match')
        else {
            let codeToDelete = false
            const codesRef = collection(db, "authCodes")
            const q = query(codesRef, where("code", "==", promptRef.current.code))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => codeToDelete = doc.id)
            if (!codeToDelete) setError('Invalid Code')
            else handleCreateNewUser(promptRef.current.email, promptRef.current.password, codeToDelete)
        }
    }

    return (
        <div>
            <div className="d-auth-header">Contributor - Register</div>
            <div className="d-auth-error-area">{error}</div>
            <div className="d-auth-body">
                <TextField 
                    onChange={handleChange}
                    name='email'
                    type='email'
                    variant="standard"
                    label='Email'
                    color="primary"
                    style={textfieldStyle}
                />
                <TextField 
                    onChange={handleChange}
                    name='password'
                    type='password'
                    variant="standard"
                    label='Password'
                    color="primary"
                    style={textfieldStyle}
                />
                <TextField 
                    onChange={handleChange}
                    name='confirm'
                    type='password'
                    variant="standard"
                    label='Confirm Password'
                    color="primary"
                    style={textfieldStyle}
                />
                <TextField 
                    onChange={handleChange}
                    type='text'
                    name='code'
                    variant="standard"
                    label='Login Code'
                    color="primary"
                    style={textfieldStyle}
                />
            </div>
            <div onClick={handleCheck} className="d-auth-submit-button">Register</div>
            <div className="d-auth-footer">Already have an account? <div className="d-auth-register-button" onClick={() => setRegistering(false) }>Sign In</div></div> 
        </div>
    )
}