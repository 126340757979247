import { useEffect } from "react"
import CheckIcon from '@mui/icons-material/Check';
export const CreateSuccess = ({ setRoute }) => {

    useEffect(() => {
        let mounted = true
        const unMount = () => {
            if (mounted) setRoute('Home')
        }
        setTimeout(() => {
            unMount()
        }, 5000)
        return () => {
            mounted = false
        }
    })

    return (
        <div>
           <div className="d-a-c-p-submission-success">
                Created new Podcast
            </div>
            <div className="d-a-c-p-submission-success-icon">
                <CheckIcon fontSize="inherit" color="inherit"/>
            </div>
           <div className="d-a-c-p-submission-success-exit" onClick={() => setRoute('Home')}>Go back to console</div>
        </div>
    )
}