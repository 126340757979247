import { Bios } from "../../Data/Bios"

export const MobileBios = () => {
    return (
        <div>
            {Bios.map((bio, i) => (
                <div className="m-bio-container mobile-column" key={`about-item-${bio.id}`}>
                    <div className="m-bio-character mobile-column">
                        <img className="m-bio-pic" src={bio.image} alt={bio.name}/>
                        <div className="m-bio-name">{bio.name}</div>
                        <div className="m-bio-title">{bio.title}</div>
                    </div>
                    <div className="m-bio-content">{bio.bio}</div>
                </div>
            ))}
        </div>
    )
}