import React from 'react';
import { AppRouter } from './AppRouter';
import reportWebVitals from './reportWebVitals';
import { GlobalContextProvider } from './GlobalContext'
import { BrowserRouter } from 'react-router-dom';

import { createRoot } from 'react-dom/client';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(88, 83, 58)',
            light: 'rgb(240,240,240)',
            dark: 'rgb(30, 30, 30)'
        },
        // secondary: {
        //     main: 'white',
        //     light: 'gray',
        //     dark: 'black'
        // },
    },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<GlobalContextProvider><BrowserRouter><ThemeProvider theme={theme}><AppRouter /></ThemeProvider></BrowserRouter></GlobalContextProvider>)

reportWebVitals();
