
export const ServicesList = [
    {
        id: 111,
        name: 'Traditional Funeral Arrangement',
        subTitles: ['Pre-Arrangements', 'Memorial Services', 'Chapel & Graveside Services', 'Cremation Services', 'Unveiling Services']
    },
    {
        id: 222,
        name: 'Support Services - Personal Wellness',
        subTitles: ['Free Online Commentaries', 'Feature Full Length Articles', 'Newsletters', 'Workshops / Classes / Courses', 'Community Outreach Programs']
    },
    {
        id: 333,
        name: 'Professional Coaching (curriculum under development)',
        subtitles: null
    }
]

export const topPart = 'We have an extensive network of professional relationships spanning the Greater Boston area coupled with a broad referral network. When you meet with us, you have an advocate who will facilitate a resolution for the challenge or challenges you may be facing when making some of life\'s most difficult decisions.'
export const middlePart = 'Our meeting will be comprehensive, capturing every detail, concern and answering all questions. If we don\'t have the answer, we will find someone who does. We follow up and maintain contact with you, to ensure you received the necessary care from our referral network.'
export const bottomPart = 'Meetings can take place in the comfort of your own home, virtually, or in a local funeral home, or local temple.'

export const servicesSections = [topPart, middlePart, bottomPart]
