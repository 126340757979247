import { TextField } from "@mui/material"
import { useState, useContext, useRef } from "react"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import { GlobalContext } from "../../GlobalContext"

//! auth/internal-error - no password
//! auth/invalid-email
//! auth/user-not-found
//! auth/wrong-password

export const SignIn = ({ setRegistering }) => {

    const { user: { setData } } = useContext(GlobalContext)
    const [error, setError] = useState('')

    
    const promptRef = useRef({
        email: '',
        password: '',
    })
    
    
    const handleLogin = () => {
        const auth = getAuth()

        signInWithEmailAndPassword(auth, promptRef.current.email, promptRef.current.password)
        .then((userCredential) => {
            console.log('success?')
            const user = userCredential.user;
            setData(user)
        })
        .catch((error) => {
            console.log('failed?', error.message)
            // const errorCode = error.code;
            setError(error.code)
        })
    }

    const textfieldStyle= { width: '70%', margin: '5px auto' }


    const handleChange = e => promptRef.current[e.target.name] = e.target.value


    return (
        <div>
           <div className="d-auth-header">Contributor - Sign In</div>
           <div className="d-auth-error-area">{error}</div>
           <div className="d-auth-body">
                <TextField 
                    onChange={handleChange}
                    name='email'
                    type='email'
                    variant="standard"
                    label='Email'
                    color="primary"
                    style={textfieldStyle}
                />
                <TextField 
                    onChange={handleChange}
                    name='password'
                    type='password'
                    variant="standard"
                    label='Password'
                    color="primary"
                    style={textfieldStyle}
                />
            </div>
            <div onClick={handleLogin} className="d-auth-submit-button">Sign In</div>
            <div className="d-auth-footer">Don't have an account? <div className="d-auth-register-button" onClick={() => setRegistering(true) }>Register</div></div> 
        </div>
    )
}