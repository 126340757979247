import second from '../../assets/SecondImage.jpeg'

export const SecondMobileImage = () => {
    return (
        <div className='m-home-third-image-container mobile-column'>
            <div className="m-home-third-middle-row">
                <img src={second} alt='landing' className='m-landing-third-image home-image' />
                <div className="m-home-middle-caption m-home-caption">
                    "Infuse your life with action. Don't wait for it to happen. Make it happen. Make your own future. Make your own hope. Make your own love. And whatever your beliefs, honor your beliefs, honor your creator, not by passively waiting for grace to come down from upon high, but by doing what you can to make grace happen…yourself, right now, right down here on Earth.”
                </div>
            </div>
            <div className="m-home-third-image-third-row m-home-caption">
                -Bradley Whitford
            </div>
            <div className="m-home-third-image-fourth-row m-home-caption">
                Submitted by Dr. David S. Briss, DMD, FRCDC
                <div className="m-home-third-image-fourth-row-item">Clinical Director,</div>
                <div className="m-home-third-image-fourth-row-item">Department of Orthodontics</div>
                <div className="m-home-third-image-fourth-row-item">Rutgers School of Dental Medicine</div>
            </div>
        </div>
    )
}