import { useParams } from 'react-router-dom'
import { useContext, useEffect, useState, useRef } from 'react'
import { GlobalContext } from '../../GlobalContext'
import { Link } from 'react-router-dom'
import { collection, query, where, getDocs, orderBy, doc, getDoc } from "firebase/firestore"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import CircularProgress from '@mui/material/CircularProgress'
import { GenerateBlogHeader } from './GenerateBlogHeader'
import { GenerateBlogContent } from './GenerateBlogContent'

export const Blog = () => {
    const { blogs: { data }, database: { db }, storage: { bucket } } = useContext(GlobalContext)

    const [loadedBlog, setLoadedBlog] = useState(false) //! false

    const [error, setError] = useState(false)
    const { id } = useParams()

    const blogDataRef = useRef()

    useEffect(() => {
        (async () => {
            if (db) {
                try {
                    const blogRef = collection(db, "blog")
                    const q = query(blogRef, where("blogId", "==", id))
                    const querySnapshot = await getDocs(q)
                    let blogEntry
    
                    querySnapshot.forEach((doc) => {
                        blogEntry = {
                            docId: doc.id,
                            ...doc.data(),
                            content: [],
                            thumbnailUrl: null
                        }
                    })
    
                    const pathReference = ref(bucket, blogEntry.pathToThumbnail)
    
                    await getDownloadURL(ref(bucket, pathReference))
                    .then(url => blogEntry.thumbnailUrl = url)
                    .catch(err => console.error(err.code))
    
                    const blogSubRef = collection(db, "blog", blogEntry.docId, "content")
    
                    const sq = query(blogSubRef)
    
                    const subQuerySnapshot = await getDocs(sq)
    
                    await Promise.all(
                        subQuerySnapshot.docs.map(async (subdoc) => {
                            let downloadURL = null;
                            if (subdoc.data().type === 'picture') {
                                downloadURL = await getDownloadURL(ref(bucket, `blog/${subdoc.data().pathToImage}`));
                            } else if (subdoc.data().type === 'youtube') {
                                downloadURL = subdoc.data().url
                            }
                    
                            blogEntry.content.push({
                                subDocID: subdoc.id,
                                ...subdoc.data(),
                                url: downloadURL
                            });
                        })
                    )

                    blogEntry.content = blogEntry.content.sort((x, y) => x.order - y.order)

                    blogDataRef.current = blogEntry

                    setLoadedBlog(true)
                } catch (err) {
                    setError(true)
                }
            }
        })()
    }, [db, bucket, id])

    const BlogBody = () => {
        const { title, subtitle, author, timestamp, thumbnailUrl, content } = blogDataRef.current

        return (
            <div className='d-guest-blog-container'>
                <GenerateBlogHeader title={title} subtitle={subtitle} author={author} timestamp={timestamp} thumbnail={thumbnailUrl} guest={true} />
                <GenerateBlogContent content={content} guest={true} />
            </div>
        )
    }


    if (error) return (
        <div className="desktop-section-container">
            <div className="d-podcast-no-podcast-message">
                Incorrect Blog ID
                <Link to='/blog' className='d-podcasts-return'>Back to Blog</Link>
            </div>
        </div>
    )

    return (
        <div className="desktop-section-container">
            {loadedBlog ? <BlogBody /> : (
                <div className='d-podcasts-loading-container'>
                <div className='d-podcasts-loading-feedback'>Loading Blog</div>
                <CircularProgress color='inherit' size={20} />
            </div>
            )}
        </div>
    )
}