import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useContext } from 'react'
import { ServicesContext } from '../../Contexts/ServicesContext'

export const ExpansionPanels = () => {

    const { preparation, toggle, planning, nonTraditional, unveiling, grief, currentlyExpanded } = useContext(ServicesContext)
    
    const order = [preparation, planning, nonTraditional, unveiling, grief]

    const accordionStyle = {
        backgroundColor: '#3d3a28',
        color: 'white',
        border: 'none'
    }, iconStyle = {
        color: 'white',
        fontSize: '40px'
    }, headerStyle = {
        fontSize: '20px',
        backgroundColor: 'transparent',
        transition: '0.2s'
    }, detailsStyle = {
        fontSize: '1.1rem',
        margin: '12px 0',
        lineHeight: 1
    }


    return (
        <div className='d-expansion-container'>
            {order.map((ex, i) => (
                <Accordion key={`ex-panel-${ex.id}`} style={accordionStyle} expanded={ex.expanded} onChange={() => toggle(ex.id, currentlyExpanded)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon style={iconStyle} />} aria-controls={`panel-${i}-content`} id={`panel-${i}-header`} style={{ textDecoration: ex.expanded ? 'underline' : null, ...headerStyle}} className='dsfhgsdhjfjsj'>
                        {ex.title}
                    </AccordionSummary>
                    <AccordionDetails>
                        {ex.expandedText.map((text, j) => (
                            <div key={`ex-panel-${i}-text-${j}`} style={detailsStyle}>
                                {text}
                            </div>
                        ))}
                        {/* <a href={`https://calendly.com/soulofthewatchman/${ex.link}`} target="_blank" rel="noreferrer"><div className="d-ex-panel-link">{ex.buttonText}</div></a> */}
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    )
}