import { Brand } from "../Home/Brand"
import { MissionStatementMobile } from "./MissionStatementMobile"
import { MobileBios } from './MobileBios'
import { useEffect } from "react";
import { useLocation } from "react-router";

export const About = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <div className="m-about-container mobile-column">
            <Brand hideBottom={true} />
            <div className="m-services-header">Our Family</div>
            <MissionStatementMobile />
            <MobileBios />
        </div>
    )
}