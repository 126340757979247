import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../GlobalContext"

export const Notifications = () => {
    const { notifications: { text, setNotification } } = useContext(GlobalContext)
    const [showing, setShowing] = useState(false)

    useEffect(() => {
        if (text !== null) {
            setTimeout(() => {
                setShowing(true)
    
                setTimeout(() => {
                    setShowing(false)
                    setTimeout(() => {
                        setNotification(null)
                    }, 500)
                }, 3500)
            }, 800)
        }
    }, [text, setNotification])

    return (
        <div className="d-notifications-container fancy-font" style={{ opacity: showing ? '100%' : '0%' }}>
           {text}
        </div>
    )
}